<div class="modal-gs p-3 d-flex flex-column gap-3">
    <header class="d-flex justify-content-between align-items-center">
        <h4 class="modal-title fs-5 pull-left">{{'MODAL_ASSISTANCE.TITLE' | translate}}</h4>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <div class="modal-gs-body">
        <div class="d-flex justify-content-center flex-wrap gap-2">
            <img alt="image-profile" src="../../../assets/images/girl-assistance.svg">
            <div class="d-flex flex-column gap-2 justify-content-center">
                <p>{{'MODAL_ASSISTANCE.DESCRIPTION' | translate}} <strong class="text-gs-primary">support&#64;gruppoglobalsistemi.it</strong></p>
                <p>{{'MODAL_ASSISTANCE.SUB_DESCRIPTION' | translate}}</p>
            </div>
        </div>
    </div>

    <footer class="d-flex gap-2 align-items-center justify-content-center">
        <button type="button" class="btn-gs-secondary" (click)="bsModalRef.hide()">{{'ACTIONS.CLOSE' | translate}}</button>
    </footer>
</div>