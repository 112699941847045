import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { ModalUtilsService } from 'src/app/shared/utilities/modal-utils.service';
import { FilterModalService } from '../../../modals/filter-modal/filter-modal.service';
import { filterImportFileModal } from '../../../modals/import-file-filter-modal/import-file-filter-modal.component';
import { ImportsFilter } from 'src/app/shared/models/import/import-filter';
import { GenericFilter } from '../../../modals/filter-modal/generic-filter';

@Component({
  selector: 'import-file-filter',
  templateUrl: './import-file-filter.component.html',
  styleUrls: ['./import-file-filter.component.scss']
})
export class ImportFileFilterComponent implements OnInit {
  @Input() titleTable: string;

  importFilter: ImportsFilter;

  filterForm: FormGroup = new FormGroup({
    inputFilter: new FormControl(),
  });

  @Output() filterTableHeaderChanged = new EventEmitter<string>();
  @Output() onFilterModalHeaderChanged = new EventEmitter<ImportsFilter>();

  private _destroy = new Subject<void>();

  constructor(
    private _modalUtilsService: ModalUtilsService,
    private _filterModalService: FilterModalService,
  ) { }

  ngOnInit(): void {
    this.getFilter();
    this.filterForm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(() => {
          this.filterTableHeaderChanged.emit(this.filterForm.value.inputFilter); 
        });
  }

  private getFilter() {
    this._filterModalService.getFilter$(filterImportFileModal)
      .pipe(takeUntil(this._destroy))
      .subscribe((filter: GenericFilter) => {
        this.importFilter = filter ? {...filter} : null;
        this.onFilterModalHeaderChanged.emit(this.importFilter);
    });
  }

  onFilterClick() {
    this._modalUtilsService.openImportFileFilterModal().pipe(
      takeUntil(this._destroy)
    ).subscribe();
  }

  removeAllFilters() {
    this.importFilter = null;
    this.onFilterModalHeaderChanged.emit(this.importFilter);
    this._filterModalService.applyFilter(filterImportFileModal, null);
  }

  removeImporterFilter() {
    this.importFilter.importerName = null;
    this.clearFilterIfEmpty();
    this.onFilterModalHeaderChanged.emit(this.importFilter);
    this._filterModalService.applyFilter(filterImportFileModal, this.importFilter);
  }

  removeStatusFilter() {
    this.importFilter.status = null;
    this.clearFilterIfEmpty();
    this.onFilterModalHeaderChanged.emit(this.importFilter);
    this._filterModalService.applyFilter(filterImportFileModal, this.importFilter);
  }

  private clearFilterIfEmpty() {
    if (Object.values(this.importFilter).every(value => !value)) {
      this.importFilter = null;
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
