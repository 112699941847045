import { Injectable } from '@angular/core';
import { Observable, Subject, map, tap } from 'rxjs';
import { Vehicle } from 'src/app/shared/models/vehicle/vehicle';
import { VehicleUsage } from 'src/app/shared/models/vehicle-usages/vehicle-usages';
import { VehicleUsageResponse } from 'src/app/shared/models/vehicle-usages/vehicle-usages-response';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { VehicleUsagesService } from 'src/app/shared/api-services/vehicle-usages.service';
import { VehiclesService } from 'src/app/shared/api-services/vehicles.service';
import { VehiclesFilter, VehicleUsageFilter } from 'src/app/shared/models/vehicle/vehicle-filter';
import { Track } from 'src/app/shared/models/track/track';
import { TrackFilter } from 'src/app/shared/models/track/track-filter';
import { TrackResponse } from 'src/app/shared/models/track/track-response';
import { VehicleResponse } from 'src/app/shared/models/vehicle/vehicle-response';
import { VehicleUsagesFilter } from 'src/app/shared/models/vehicle-usages/vehicle-usage-filter';

@Injectable({
  providedIn: 'root'
})
export class FleetlocationService {
  tenantId = this._tenantService.getTenantId();

  vehicles$: Subject<VehicleResponse> = new Subject<VehicleResponse>();
  vehicle$: Subject<Vehicle> = new Subject<Vehicle>();
  tracks$: Subject<Track[]> = new Subject<Track[]>();

  showCard$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _tenantService: TenantService,
    private _vehiclesService: VehiclesService,
    private _vehiclesUsageService: VehicleUsagesService
  ) { }

  getVehicles$(vehicleFilter: VehiclesFilter = {}): Observable<VehicleResponse> {

    let params: VehiclesFilter = {
      ...vehicleFilter,
      start: 0,
      limit: 10,
      orderBy: '-Id',
      includeMake: true,
      includeModel: true,
      includeVersion: true,
      includeVehicleType: true,
      includePurchaseType: true,
      includeHeadquarter: true,
      includeLocation: true,
      hasPosition: true,
    }

    return this._vehiclesService.listVehicles$(this.tenantId, params)
      .pipe(
        tap(
          (response: VehicleResponse) => {
            this.vehicles$.next(response);
          }
        )
      );
  }

  getVehicleUsage$(vehicleId: string): Observable<VehicleUsage> {
    let params: VehicleUsagesFilter = {
      vehicleId,
      includeUser: true,
      limit: 1,
      orderBy: '-Id'
    };
    return this._vehiclesUsageService.listVehicleUsages$(this.tenantId, params)
      .pipe(
        map(
          (response: VehicleUsageResponse) => {
            if (response && response.items && response.items.length > 0) {
              return response.items[0];
            }
            return null;
          }
        )
      );
  }

  listVehicleTracks$(vehicleId: string, startDate?: string, endDate?: string): Observable<Track[]> {
    let params: TrackFilter = {
      rangeStartDate: startDate ?? null,
      rangeEndDate: endDate ?? null,
      start: 0,
      limit: 500,
      orderBy: '-Id'
    }
    return this._vehiclesService.listVehicleTracks$(this.tenantId, vehicleId, params)
      .pipe(
        map(
          (response: TrackResponse) => {
            this.sendTracks(response.items);
            return response.items;
          }
        )
      );
  }

  sendVehicle(vehicle: Vehicle) {
    this.vehicle$.next(vehicle);
  }

  sendTracks(tracks: Track[]) {
    this.tracks$.next(tracks);
  }

  sendShowCard(showCard: boolean) {
    this.showCard$.next(showCard);
  }
}
