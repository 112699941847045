<div class="card-modal">
    <header class="card-modal-header p-3">
        <h4 class="card-modal-title" *ngIf="this.modalStatus.isCreate">
            {{ 'INVOICES.NEW_INVOICE' | translate }}
        </h4>

        <h4 class="card-modal-title" *ngIf="this.modalStatus.isPatch">
            {{ 'INVOICES.EDIT_INVOICE' | translate }}
        </h4>
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <content>
        <form [formGroup]="invoiceForm">

            <div class="container-fleet-fields d-flex gap-3 flex-column">

                <div class="form-group px-3">
                    <label for="supplier" class="form-label">
                        {{'SUPPLIER' | translate}}
                        <span class="text-danger">*</span>
                    </label>        
                    <input type="text" class="form-control" [placeholder]="'INVOICES.INSERT_SUPPLIER' | translate"
                        formControlName="supplier"
                        [ngClass]="{'is-invalid': invoiceForm.get('supplier')?.invalid && (invoiceForm.get('supplier')?.dirty || invoiceForm.get('supplier')?.touched)}"
                        [isAnimated]="true"
                        [typeahead]="supplier$"
                        [typeaheadAsync]="true"
                        [typeaheadMinLength]="0"
                        (typeaheadOnSelect)="onSupplierSelected($event)">
                    
                    <div class="d-flex flex-wrap gap-2 mt-2">
                        <button type="button" class="btn-sm-gs-full-secondary d-flex align-items-center gap-1" *ngIf="selectedSupplier">
                            {{selectedSupplier?.name}}
                            <button type="button" class="btn-close pull-right" aria-label="Close" (click)="removeSupplier()"></button>
                        </button>
                    </div>
                </div>

                <div class="row px-3">

                    <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <label for="name" class="form-label">
                            {{ 'INVOICES.invoiceNumber' | translate }}
                            <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" [ngClass]="{'is-invalid': invoiceForm.get('invoiceNumber')?.invalid && (invoiceForm.get('invoiceNumber')?.dirty || invoiceForm.get('invoiceNumber')?.touched)}" formControlName="invoiceNumber">
                    </div>
    
                    <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <label for="license-plate" class="form-label">
                            {{ 'INVOICES.invoiceDate' | translate }}
                        </label>
                        <input-date-picker formControlName="invoiceDate"></input-date-picker>
                    </div>

                </div>

                <div class="row px-3">

                    <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <label for="netAmount" class="form-label">
                            {{ 'INVOICES.netAmount' | translate }}
                        </label>
                        <input type="number" class="form-control" formControlName="netAmount">
                    </div>
    
                    <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <label for="grossAmount" class="form-label">
                            {{ 'INVOICES.grossAmount' | translate }}
                        </label>
                        <input type="number" class="form-control" formControlName="grossAmount">
                    </div>

                </div>



                <div class="form-group px-3">
                    <label for="tag" class="form-label">{{'TAG' | translate}}</label>        
                    <input type="text" class="form-control" [placeholder]="'INSERT_TAG' | translate"
                        formControlName="tag"
                        [isAnimated]="true"
                        [typeahead]="tags$"
                        [typeaheadAsync]="true"
                        [typeaheadMinLength]="0"
                        (typeaheadOnSelect)="onTagSelected($event)">
                    
                    <div class="d-flex flex-wrap gap-2 mt-2">
                        <button type="button" class="btn-sm-gs-full-secondary d-flex align-items-center gap-1" *ngFor="let tag of selectedTags">
                            {{tag.name}}
                            <button type="button" class="btn-close pull-right" aria-label="Close" (click)="removeTag(tag.id)"></button>
                        </button>
                    </div>
                </div>

            </div>

            <div class="container-downloads d-flex flex-column gap-4 p-3 mt-2">
                <div class="title-small">{{'INVOICES.DOCUMENTS' | translate}}</div>
                <input-upload-files (fileInfo)="setInCacheFilesToUpload($event)" [tenantId]="tenantId" [bucketName]="bucketName"></input-upload-files>                        
                <output-files [documents]="documents" (downloadDocument)="downloadFile($event)" (deleteDocument)="setInCacheFileToRemove($event)"></output-files>
            </div>

            <div class="container-tasks-linked-to-invoice p-3">
                <div class="form-group">
                    <label for="invoiceName">{{ 'TASKS.LINK_TASKS' | translate }}</label>
                    <ng-select
                        [items]="listTaskToShow"
                        #select
                        bindLabel="invoiceNumber"
                        dropdownPosition="auto"
                        bindValue="id"
                        [placeholder]="'TASKS.INSERT_TASK_TITLE' | translate"
                        [virtualScroll]="true"
                        (change)="addTaskToInvoice($event)"
                    >
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <div class="fw-500">
                                {{ item?.title }} | {{ item.taskType?.name | translate }}
                            </div>
                            <small>
                                @if (item.taskCost?.accountingDate) {
                                    <div>{{ item.taskCost?.accountingDate| date:'dd/MM/yyyy' }}, </div>
                                }
                                @if (item.taskCost?.grossCost) {
                                    <div>{{ item.taskCost?.grossCost| number:'1.2-2' }}</div>
                                }
                            </small>
                        </ng-template>
                    </ng-select>
                </div>

                @if (listTasksLinkedToInvoice.length > 0) {
                    <div class="d-flex flex-column gap-3 mt-4">
                        <h5>{{ 'TASKS.LINKED_TASKS' | translate }}</h5>
                        @for (item of listTasksLinkedToInvoice; track item) {
                            <div class="d-flex justify-content-between">
                                <div class="d-flex flex-column">
                                    <div class="small-title cursor-pointer" (click)="openTaskModal(item.task?.id)">{{ item.task?.title }} | {{ item.task?.taskType?.name | translate }}</div>
                                    <div class="info d-flex gap-2">
                                        @if (item.task?.taskCost?.accountingDate) {
                                            <div tooltipPosition="top" [pTooltip]="'AccountingDate' | translate" >{{ item.task?.taskCost?.accountingDate | date:'dd/MM/yyyy' }}</div>
                                        }
                                        @if (item.task?.taskCost?.netCost) {
                                            <div tooltipPosition="top" [pTooltip]="'NetCost' | translate" > | {{ item.task?.taskCost?.netCost| number:'1.2-2' }}</div>
                                        }
                                        @if (item.task?.taskCost?.grossCost) {
                                            <div tooltipPosition="top" [pTooltip]="'GrossCost' | translate" > | {{ item.task?.taskCost?.grossCost| number:'1.2-2' }}</div>
                                        }
                                        @if (item.task?.vehicle?.licensePlate) {
                                            <div tooltipPosition="top" [pTooltip]="'LicensePlate' | translate" > | {{ item.task?.vehicle?.licensePlate }}</div>
                                        }
                                        @if (item.task?.user?.displayName) {
                                            <div tooltipPosition="top" [pTooltip]="'User' | translate" > | {{ item.task?.user?.displayName }}</div>
                                        }
                                        @if (item.task?.asset?.displayName) {
                                            <div tooltipPosition="top" [pTooltip]="'Asset' | translate" > | {{ item.task?.asset?.displayName }}</div>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <button class="btn btn-danger">
                                        <i class="bi bi-trash" (click)="onTaskInvoiceLinkDelete(item.id)"></i>
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                }
                
            </div>

            <div class="container-fleet-fields d-flex gap-3 flex-column p-3" *ngIf="fields && fields?.length > 0">
                <div class="title">{{'VEHICLES.ADDITIONAL_INFORMATION' | translate}}</div>
                <formly-form [form]="invoiceForm" [fields]="fields"></formly-form>
            </div>
            
        </form>
    </content>
    
    <footer>
        <div class="d-flex gap-3 justify-content-center p-3">
            <div type="button" class="btn-gs-secondary-light" (click)="this.bsModalRef.hide()">
                {{ 'ACTIONS.CANCEL' | translate }}
            </div>
    
            <button type="submit" class="btn-gs-primary" (click)="onSubmit()">
                {{ 'ACTIONS.SAVE' | translate }}
            </button>
        </div>
    </footer>
</div>