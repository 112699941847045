import { HttpClient } from '@angular/common/http';
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { Injectable } from '@angular/core';
import { Observable, of, shareReplay, tap, timeout } from 'rxjs';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { TokenResp } from '../models/token-response';
import { IdpResponse } from '../models/idb-response';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SentryUtilsService } from '../utilities/sentry-utils.servic';
import { LoginForm } from 'src/app/pages/login/login.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private idpResponse$: Observable<IdpResponse> | null = null;
  private lastFetchTime: number | null = null;
  private idpCacheTimeout = 300000;

  constructor(
    private _httpClient: HttpClient,
    private _router: Router,
    private _toastrService: ToastrService,
    private _sentryUtilsService: SentryUtilsService
  ) { }

  getIdp$(tenantId: string): Observable<IdpResponse> {
    const currentTime = Date.now();

    if (this.idpResponse$ && this.lastFetchTime && (currentTime - this.lastFetchTime < this.idpCacheTimeout)) {
      return this.idpResponse$;
    }

    return this.fetchIdpResponse(tenantId, currentTime);
  }

  private fetchIdpResponse(tenantId: string, currentTime: number): Observable<IdpResponse> {
    const timeoutDuration = 3000;
    return this._httpClient.get<IdpResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/idp`).pipe(
      timeout(timeoutDuration),
      tap(idpResponse => this.cacheIdpResponse(idpResponse, currentTime)),
      tap({
        error: error => this.handleError(error)
      }),
      shareReplay(1)
    );
  }

  private cacheIdpResponse(idpResponse: IdpResponse, currentTime: number): void {
    this.idpResponse$ = of(idpResponse);
    this.lastFetchTime = currentTime;
  }

  private handleError(error: any): Observable<any> {
    if (error.name === 'TimeoutError' || error.status === 0 || error.status === 404 || error.status === 500) {
      this._router.navigate([RoutesUrl.LOGIN]);
    }
    this._toastrService.error(error?.error?.detail, error?.error?.title);
    this._sentryUtilsService.sendEventErrorForSentry(error);
    return error;
  }

  getToken$(loginForm: LoginForm, tenantId: string): Observable<TokenResp> {
    return this._httpClient.post<TokenResp>(`${VECTORE_API_BASE_URL}/${tenantId}/token`, loginForm)
      .pipe(
        shareReplay(1)
      );
  }

  getRefreshToken$(refreshToken: string, tenantId: string): Observable<TokenResp> {
    return this._httpClient.post<TokenResp>(`${VECTORE_API_BASE_URL}/${tenantId}/refresh-token`, { refreshToken })
      .pipe(
        shareReplay(1)
      );
  }
}
