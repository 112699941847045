<div class="filters mb-3">
  <div class="filters-section-left btn-group">
      <h3 class="table-title">{{ titleTable | translate}}</h3>
  </div>

  <div  class="filters-section-right">
    <form [formGroup]="filterForm">

      <div class="input-group d-flex gap-1">

        <dropdown-primary 
          [(selectedOption)]="filterHeader"
          [emitNgOnInit]="false"
          [options]="listTableFilterHeaders"
          (selectedOptionChange)="selectedOption($event)"
          [iconClass]="'bi bi-chevron-down'"
          [cssClass]="'btn-lightgray rounded-1'">
        </dropdown-primary>

        <input type="text" class="form-control rounded-1" formControlName="inputFilter">

      </div>
  
    </form>

    <button-first-type 
        [cssClass]="'btn-gs-secondary-icon'"
        [iconClass]="'bi bi-funnel'" 
        [buttonText]= "'FILTERR'"
        (click)="onFilterClick()">
    </button-first-type>
    
    <button-first-type 
        [cssClass]="'btn-gs-secondary-icon'"
        [iconClass]="'bi bi-download'" 
        [buttonText]= "'EXPORT' | translate"
        (click)="onClickExport()">
    </button-first-type>

    @if (hasManageUserPermission) {
      <dropdown-primary 
        [emitNgOnInit]="false"
        [selectedOption]="{ value: '', text: 'ACTIONS.NAME' | translate }"
        [options]="listMassiveActions"
        [isStaticSelectOption] = "true"
        (selectedOptionChange)="selectMassiveOption($event)"
        [iconClass]="'bi bi-three-dots-vertical'"
        [cssClass]="'btn-gs-primary-icon flex-row-reverse'">
      </dropdown-primary>
    }
  </div>
</div>

@if (userFilter?.headquarter || 
     (userFilter?.tags && userFilter?.tags.length > 0) || 
     (userFilter?.groups && userFilter?.groups.length > 0) ||
     userFilter?.hasGroups !== undefined) {
  <div class="container-filters d-flex gap-3 align-items-center justify-content-end mb-3">

    @if (userFilter?.headquarter) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'HEADQUARTER.NAME' | translate }}: {{userFilter?.headquarter?.name}}</span>
        <i class="bi bi-x" (click)="removeFilter('headquarter', 'headquarterId')"></i>
      </button>
    }

    @if (userFilter?.groups && userFilter?.groups?.length > 0) {
      <button class="btn-gs-filter" *ngFor="let group of userFilter?.groups">
        <i class="bi bi-funnel"></i>
        <span>{{ 'GROUP' | translate }}: {{group?.name}}</span>
        <i class="bi bi-x" (click)="removeFilterArray('groups', 'groupIds', group?.id)"></i>
      </button>
    }

    @if (userFilter?.tags && userFilter?.tags?.length > 0) {
      <button class="btn-gs-filter" *ngFor="let tag of userFilter?.tags">
        <i class="bi bi-funnel"></i>
        <span>{{ 'TAG' | translate }}: {{tag?.name}}</span>
        <i class="bi bi-x" (click)="removeFilterArray('tags', 'tagIds', tag?.id)"></i>
      </button>
    }

    @if (userFilter?.hasGroups !== undefined) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ userFilter?.hasGroups ? ('HAS_GROUPS' | translate) : ('NOT_HAS_GROUPS' | translate) }}</span>
        <i class="bi bi-x" (click)="removeHasBoolean('hasGroups')"></i>
      </button>
    }

    <button class="btn-gs-secondary-trash" (click)="removeAllFilters()">
      <i class="bi bi-trash"></i>
    </button>
    
  </div>
}