import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, shareReplay, tap } from 'rxjs';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { CommonService } from './common.service';
import { Me, Notification } from '../models/me/me';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MeRequest, NotificationRequest, SendOtpRequest, VerifyUserRequest } from '../models/me/me-request';
import { PermissionResponse } from '../models/me/me-response';
import { HeadquarterResponse } from '../models/headquarter/headquarter-response';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class MeService {
  private _tenantId: string;
  private userImageCache = new Map<string, Observable<any>>();
  me$: BehaviorSubject<Me> = new BehaviorSubject<Me | null>(null);

  constructor(
    private _tenantService: TenantService,
    private _http: HttpClient,
    private _commonService: CommonService,
    private _domSanitizer: DomSanitizer,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
  ) { 
    this._tenantId = this._tenantService.getTenantId();
  }

  sendMe(tenantId: string): void {
    this._http.get<Me>(`${VECTORE_API_BASE_URL}/${tenantId}/me`)
      .pipe(
        shareReplay(1),
        tap(me => {
          this.me$.next(me);
        })
      ).subscribe();
  }

  registerMe$(createMeRequest: MeRequest): Observable<Me> {
    return this._http.post<Me>(`${VECTORE_API_BASE_URL}/${this._tenantId}/me`, createMeRequest)
      .pipe(
        tap({
          error: error => {
            this._toastrService.info(this._translateService.instant('PERSONS.MESSAGES.' + error.error.errorCode));
            this._commonService.handleError(error, false);
          }
        })
      );
  }

  getMe$(): Observable<Me> {
    return this._http.get<Me>(`${VECTORE_API_BASE_URL}/${this._tenantId}/me`)
      .pipe(
        shareReplay(1)
      );
  }

  patchMe$(patchMeRequest: MeRequest): Observable<Me> {
    return this._http.patch<Me>(`${VECTORE_API_BASE_URL}/${this._tenantId}/me`, patchMeRequest)
      .pipe(
        tap({
          next: response => {
            if (response.id) {
              this._toastrService.success(this._translateService.instant('PERSONS.MESSAGES.SUCCESS_UPDATED'));
            }
          },
          error: error => {
            this._toastrService.error(this._translateService.instant('PERSONS.MESSAGES.' + error.error.errorCode));
            this._commonService.handleError(error);
          }
        }),
        // tap((updatedMe: Me) => {
        //   this.me = updatedMe;
        //   this.lastFetchTime = Date.now();
        // })
      );
  }

  getMeImage$(tenantId: string, width?: number, height?: number, safe: boolean = true): Observable<SafeUrl | string | null> {
    const cacheKey = `${tenantId}-${width}-${height}`;

    if (!this.userImageCache.has(cacheKey)) {
      const params = {
        width: width,
        height: height
      };
      const requestObservable = this._http.get<any>(`${VECTORE_API_BASE_URL}/${tenantId}/me/image`, {
        params: this._commonService.buildParams(params),
        responseType: 'blob' as 'json'
      }).pipe(
        tap({
          error: error => this._commonService.hanleErrorImage(error)
        })
      );

      this.userImageCache.set(cacheKey, requestObservable);
    }
    return this.userImageCache.get(cacheKey).pipe(
      map(response => {
        if (response) {
          let urlCreator = window.URL || window.webkitURL;
          if (!safe) {
            return urlCreator.createObjectURL(response);
          }
          return this._domSanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response));
        }
        return null;
      })
    );
  }

  sendOtp$(sendOtpRequest: SendOtpRequest): Observable<HttpResponse<Object>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${this._tenantId}/me/send-otp`, sendOtpRequest, { observe: 'response' })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  verifyUser$(verifyUserRequest: VerifyUserRequest): Observable<HttpResponse<Object>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${this._tenantId}/me/verify-user`, verifyUserRequest, { observe: 'response' })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listNotifications$(tenantId: string, notificationRequest?: NotificationRequest): Observable<Notification[]> {
    return this._http.get<Notification[]>(`${VECTORE_API_BASE_URL}/${tenantId}/me/notifications`, {
      params: this._commonService.buildParams(notificationRequest)
    })
      .pipe(
        tap({
          error: error => this._commonService.handleErrorForGetNotFound(error)
        })
      );
  }

  updateNotification$(tenantId: string, notificationId: string, notificationRequest: NotificationRequest): Observable<Notification> {
    return this._http.patch<Notification>(`${VECTORE_API_BASE_URL}/${tenantId}/me/notifications/${notificationId}`, notificationRequest)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  markAsReadNotifications$(tenantId: string): Observable<HttpResponse<Object>> {
    return this._http.post<Object>(`${VECTORE_API_BASE_URL}/${tenantId}/me/notifications/mark-as-read`, null, { observe: 'response' })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listMeHeadquarters$(): Observable<HeadquarterResponse> {
    return this._http.get<HeadquarterResponse>(`${VECTORE_API_BASE_URL}/${this._tenantId}/me/headquarters`);
  }

  getMePermissions$(tenantId: string): Observable<PermissionResponse> {
    return this._http.get<PermissionResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/me/permissions`);
  }
}