import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DropdownOption } from '../../../dropdown/dropdown-primary/dropdown.interface';
import { Observable, Observer, debounceTime, distinctUntilChanged, map, switchMap, Subject, takeUntil } from 'rxjs';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FilterModalService } from '../filter-modal.service';
import { HeadquartersService } from 'src/app/shared/api-services/headquarters.service';
import { HeadquarterResponse } from 'src/app/shared/models/headquarter/headquarter-response';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DropdownModule } from '../../../dropdown/dropdown.module';
import { GenericFilter } from '../generic-filter';
import { TagsService } from 'src/app/shared/api-services/tags.service';
import { ToastrService } from 'ngx-toastr';
import { TagResponse } from 'src/app/shared/models/tag/tag-response';
import { MakesService } from 'src/app/shared/api-services/makes.service';
import { MakeResponse, ModelResponse, VersionResponse } from 'src/app/shared/models/make/make-response';

export const filterVehicleModal = 'filter-vehicle-modal';

@Component({
  selector: 'filter-vehicle-modal',
  templateUrl: './filter-vehicle-modal.component.html',
  styleUrls: ['./filter-vehicle-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TypeaheadModule,
    FormsModule,
    TranslateModule,
    DropdownModule,
  ]
})
export class FilterVehicleModalComponent implements OnInit, OnDestroy {
  form: FormGroup;
  genericFilter: GenericFilter = {};

  makeName$: Observable<string[]>;
  modelName$: Observable<string[]>;
  versionName$: Observable<string[]>;

  headquarterName$: Observable<string[]>;
  locationName$: Observable<string[]>;
  tagName$: Observable<string[]>;
  
  private _destroy$ = new Subject<void>();

  constructor(
    private _makesService: MakesService,
    private _headquartersService: HeadquartersService,
    private _tagsService: TagsService,
    private _filterModalService: FilterModalService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    public bsModalRef: BsModalRef
  ) {
    this.form = new FormGroup({
      vehicleTypeId: new FormControl(null),
      purchaseTypeId: new FormControl(null),
      fuelTypeId: new FormControl(null),

      makeId: new FormControl(null),
      makeName: new FormControl(null),

      modelId: new FormControl(null),
      modelName: new FormControl(null),

      versionId: new FormControl(null),
      versionName: new FormControl(null),

      headquarterId: new FormControl(null),
      headquarterName: new FormControl(null),

      locationId: new FormControl(null),
      locationName: new FormControl(null),

      tagId: new FormControl(null),
      tagName: new FormControl(null),

      isExit: new FormControl(null),
      isSuspended: new FormControl(null),
      closeOnApply: new FormControl(true),
    });
  }

  ngOnInit() {
    this.autoComplete();
  }

  private autoComplete() {
    this.makeName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['makeName'].value);
    }).pipe(
      takeUntil(this._destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._makesService.listMakesNew$(
        { nameContains: token, limit: 5 })),
      map((response: MakeResponse) => {
        let displayNames: string[] = [];
        response.items.forEach((make) => {
          displayNames.push(make.name);
        });
        return displayNames;
      }),
    );

    this.modelName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['modelName'].value);
    }).pipe(
      takeUntil(this._destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._makesService.listModelsNew$(
        { makeId: this.form.value.makeId, nameContains: token, limit: 5 })),
      map((response: ModelResponse) => {
        let displayNames: string[] = [];
        response.items.forEach((model) => {
          displayNames.push(model.name);
        });
        return displayNames;
      }),
    );

    this.versionName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['versionName'].value);
    }).pipe(
      takeUntil(this._destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._makesService.listVersionsNew$(
        { makeId: this.form.value.makeId ?? null, modelId: this.form.value.modelId ?? null, nameContains: token, limit: 5 })),
      map((response: VersionResponse) => {
        let displayNames: string[] = [];
        response.items.forEach((version) => {
          displayNames.push(version.name);
        });
        return displayNames;
      }),
    );

    this.headquarterName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['headquarterName'].value);
    }).pipe(
      takeUntil(this._destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._headquartersService.listHeadquartersNew$(
        { nameContains: token, limit: 5 })),
      map((response: HeadquarterResponse) => {
        let displayNames: string[] = [];
        response.items.forEach((headquarter) => {
          displayNames.push(headquarter.name);
        });
        return displayNames;
      }),
    );

    this.locationName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['locationName'].value);
    }).pipe(
      takeUntil(this._destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._headquartersService.listLocationsNew$(
        { nameContains: token, headquarterId: this.form.value.headquarterId, limit: 5 })),
      map((response: HeadquarterResponse) => {
        let displayNames: string[] = [];
        response.items.forEach((headquarter) => {
          displayNames.push(headquarter.name);
        });
        return displayNames;
      }),
    );

    this.tagName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['tagName'].value);
    }).pipe(
      takeUntil(this._destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._tagsService.listTagsNew$({ nameContains: token, limit: 5 })),
      map((response: TagResponse) => {
        let displayNames: string[] = [];
        response.items.forEach((tag) => {
          displayNames.push(tag.name);
        });
        return displayNames;
      }),
    );
  }

  onVehicleTypeSelected($event: DropdownOption) {
    this.form.patchValue({ vehicleTypeId: $event.value });
    this.genericFilter.vehicleType = $event.extraField.item;
  }

  onPurchaseTypeSelected($event: DropdownOption) {
    this.form.patchValue({ purchaseTypeId: $event.value });
    this.genericFilter.purchaseType = $event.extraField.item;
  }

  onFuelTypeSelected($event: DropdownOption) {
    this.form.patchValue({ fuelTypeId: $event.value });
    this.genericFilter.fuelType = $event.extraField.item;
  }

  onSelectMake(match: TypeaheadMatch) {
    const name = match.value;
    this._makesService.listMakesNew$({ name: name })
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.items.length > 0) {
            this.form.patchValue({ 
              makeId: response.items[0].id, 
              makeName: response.items[0].name,
              modelId: null,
              modelName: null,
              versionId: null,
              versionName: null,
            });
            this.genericFilter.make = response.items[0];
          }
        }
      });
  }

  onSelectModel(match: TypeaheadMatch) {
    const name = match.value;
    this._makesService.listModelsNew$({ makeId: this.form.value.makeId ?? null, name: name })
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.items.length > 0) {
            this.form.patchValue({ 
              modelId: response.items[0].id, 
              modelName: response.items[0].name,
              versionId: null,
              versionName: null,
            });
            this.genericFilter.model = response.items[0];
          }
        }
      });
  }

  onSelectVersion(match: TypeaheadMatch) {
    const name = match.value;
    this._makesService.listVersionsNew$({ makeId: this.form.value.makeId ?? null, modelId: this.form.value.modelId ?? null, name: name })
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.items.length > 0) {
            this.form.patchValue({ 
              versionId: response.items[0].id, 
              versionName: response.items[0].name,
            });
            this.genericFilter.version = response.items[0];
          }
        }
      });
  }

  onSelectHeadquarter(match: TypeaheadMatch) {
    const name = match.value;
    this._headquartersService.listHeadquartersNew$({ name: name })
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.items.length > 0) {
          this.form.patchValue({
            headquarterId: response.items[0].id,
            headquarterName: response.items[0].name,
            locationId: null,
            locationName: null,
          });
          this.genericFilter.headquarter = response.items[0];
        }
      }
    });
  }

  onSelectLocation(match: TypeaheadMatch) {
    const name = match.value;
    this._headquartersService.listLocationsNew$({ name: name })
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.items.length > 0) {
            this.form.patchValue({
            locationId: response.items[0].id,
            locationName: response.items[0].name,
          });
          this.genericFilter.location = response.items[0];
        }
      }
    });
  }

  onSelectTag(match: TypeaheadMatch) {
    const name = match.value;
    this._tagsService.listTagsNew$({ name: name })
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.items.length > 0) {
            this.form.patchValue({
            tagId: response.items[0].id,
            tagName: response.items[0].name,
          });
          this.genericFilter.tag = response.items[0];
        }
      }
    });
  }

  onSelectedIsExit(optionSelected: DropdownOption) {
    if (optionSelected.value === 'active') {
      this.genericFilter.isExit = true;
    } else if (optionSelected.value === 'inactive') {
      this.genericFilter.isExit = false;
    } else {
      this.genericFilter.isExit = null;
    }
  }

  onSelectedIsSuspended(optionSelected: DropdownOption) {
    if (optionSelected.value === 'active') {
      this.genericFilter.isSuspended = true;
    } else if (optionSelected.value === 'inactive') {
      this.genericFilter.isSuspended = false;
    } else {
      this.genericFilter.isSuspended = null;
    }
  }

  apply() {
    this._toastrService.success(this._translateService.instant('FILTER_APPLYED'));
    this._filterModalService.applyFilter(filterVehicleModal, this.genericFilter);
    if (this.form.controls['closeOnApply'].value) {
      this.bsModalRef.hide();
      this.genericFilter = {};
      this.form.reset();
    }
  }

  close() {
    this.form.reset();
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}