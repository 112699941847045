<div class="filters mb-3">
    <div class="filters-section-left btn-group">
        <h3 class="table-title">{{ titleTable | translate}}</h3>
    </div>

    <div class="filters-section-right">
        <form [formGroup]="filterForm">
            <div class="input-group text-black-50 d-flex align-items-center border rounded">

              <input type="text" class="from-date form-control border-0" 
                [placeholder]="'START_DATE' | translate" 
                bsDatepicker 
                formControlName="startDate" 
                inputmode='none'
                [bsConfig]="{ 
                    dateInputFormat: 'DD-MM-YYYY', 
                    isAnimated: true, 
                    adaptivePosition: true 
                  }">
              <i class="bi bi-arrow-right"></i>

              <input type="text" class="to-date form-control border-0" 
                [placeholder]="'END_DATE' | translate" 
                bsDatepicker 
                formControlName="endDate" 
                [bsConfig]="{ 
                    dateInputFormat: 'DD-MM-YYYY', 
                    isAnimated: true, 
                    adaptivePosition: true 
                  }">
              <i class="bi bi-calendar-check"></i>
            </div>
        </form>

      <button-first-type 
          [cssClass]="'btn-gs-secondary-icon'"
          [iconClass]="'bi bi-funnel'" 
          [buttonText]= "'FILTERR'"
          (click)="onFilterClick()">
      </button-first-type>
      
      <button-first-type 
          [cssClass]="'btn-gs-secondary-icon'"
          [iconClass]="'bi bi-download'" 
          [buttonText]= "'EXPORT' | translate"
          (click)="onClickExport()">
      </button-first-type>
    </div>  
</div>

@if (vehicleUsageFilter?.user || vehicleUsageFilter?.startUser || vehicleUsageFilter?.endUser || vehicleUsageFilter?.vehicle || vehicleUsageFilter?.headquarter || vehicleUsageFilter?.location) {
  <div class="container-filters d-flex gap-3 align-items-center justify-content-end mb-3">

    @if (vehicleUsageFilter?.user) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'BOOKINGS.DRIVER' | translate }}: {{vehicleUsageFilter?.user?.displayName}}</span>
        <i class="bi bi-x" (click)="removeFilter('user', 'userId')"></i>
      </button>
    }

    @if (vehicleUsageFilter?.startUser) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'BOOKINGS.DRIVER_START' | translate }}: {{vehicleUsageFilter?.startUser?.displayName}}</span>
        <i class="bi bi-x" (click)="removeFilter('startUser', 'startUserId')"></i>
      </button>
    }

    @if (vehicleUsageFilter?.endUser) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'BOOKINGS.DRIVER_END' | translate }}: {{vehicleUsageFilter?.endUser?.displayName}}</span>
        <i class="bi bi-x" (click)="removeFilter('endUser', 'endUserId')"></i>
      </button>
    }

    @if (vehicleUsageFilter?.vehicle) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'VEHICLE' | translate }}: {{vehicleUsageFilter?.vehicle?.displayName}}</span>
        <i class="bi bi-x" (click)="removeFilter('vehicle', 'vehicleId')"></i>
      </button>

      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'LICENSE_PLATE' | translate }}: {{vehicleUsageFilter?.vehicle?.licensePlate}}</span>
        <i class="bi bi-x" (click)="removeFilter('vehicle', 'vehicleId')"></i>
      </button>
    }

    @if (vehicleUsageFilter?.headquarter) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'HEADQUARTER.NAME' | translate }}: {{vehicleUsageFilter?.headquarter?.name}}</span>
        <i class="bi bi-x" (click)="removeFilter('headquarter', 'headquarterId')"></i>
      </button>
    }

    @if (vehicleUsageFilter?.location) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'LOCATION.NAME' | translate }}: {{vehicleUsageFilter?.location?.name}}</span>
        <i class="bi bi-x" (click)="removeFilter('location', 'locationId')"></i>
      </button>
    }

    <button class="btn-gs-secondary-trash" (click)="removeAllFilters()">
      <i class="bi bi-trash"></i>
    </button>
    
  </div>
}