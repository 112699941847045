import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'assistance-modal',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
  ],
  templateUrl: './assistance-modal.component.html',
})
export class AssistanceModalComponent {

  constructor(
    public bsModalRef: BsModalRef
  ) {}

}
