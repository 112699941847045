<li class="sidebar-item" data-toggle="tooltip" data-placement="right" 
    *ngFor="let item of listItemsSidebar" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">

    <ng-container *ngIf="item.children; else singleLink">
        <a class="sidebar-item-link-with-children collapsed" type="button" data-bs-toggle="collapse" 
            [attr.data-bs-target]="'#' + item.label" aria-expanded="false" aria-controls="collapseExample"
            (click)="toggleChevron(item)">

            <div class="accordion-left">                            
                <i [class]="item.icon"></i>
                <span>{{item.label}}</span>
            </div>
            <i [ngClass]="[item.children ? (item.isExpanded ? 'bi-chevron-up rotate-down' : 'bi-chevron-up rotate-up') : '']"></i>

        </a>
        
        <div class="collapse" [id]="item.label">
            <div class="accordion-body">
                <ul class="sidebar-list-child">
                    <li *ngFor="let child of item.children" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <a class="link-child" [href]="child.href" *ngIf="child.href">{{child.label}}</a>
                        <a class="link-child" [routerLink]="child.routerLink" *ngIf="child.routerLink">{{child.label}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>

    <ng-template #singleLink>
        <a [class]="item.color" [routerLink]="item.routerLink" [queryParams]="item.linkQueryParam" *ngIf="item.routerLink" type="button">
            <i [class]="item.icon"></i>
            <span>{{item.label}}</span>
        </a>
        <a [class]="item.color" [href]="item.href" *ngIf="item.href" type="button">
            <i [class]="item.icon"></i>
            <span>{{item.label}}</span>
        </a>
    </ng-template>
</li>