import * as Sentry from "@sentry/angular-ivy";
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserModel } from 'src/app/shared/models/user/users';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { UsersService } from 'src/app/shared/api-services/users.service';
import { Observable, Subject, concatMap, finalize, forkJoin, map, takeUntil, tap } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { DropdownOption } from "../../dropdown/dropdown-primary/dropdown.interface";
import { HeadquartersService } from "src/app/shared/api-services/headquarters.service";
import { SafeUrl } from "@angular/platform-browser";
import { MeService } from "src/app/shared/api-services/me.service";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { InfoService } from "src/app/shared/api-services/info.service";
import { ExtraFieldsUtilsService } from "src/app/shared/utilities/extra-fields-utils.servic";
import { StorageUtilsService } from "src/app/shared/utilities/storage-utils.servic";
import { TypeExtraField } from "src/app/shared/models/common";
import { MeRequest } from "src/app/shared/models/me/me-request";
import { Me } from "src/app/shared/models/me/me";
import { UserRequest } from "src/app/shared/models/user/user-request";
import { TenantInfo } from "src/app/shared/models/info/info";

@Component({
  selector: 'person-modal',
  templateUrl: './person-modal.component.html',
  styleUrls: ['./person-modal.component.scss']
})
export class PersonModalComponent implements OnInit, OnDestroy {

  tenantId = this._tenantService.getTenantId();
  tenantInfos: TenantInfo;
  @Input() user?: UserModel;
  @Input() me?: Me;

  @Input() isUser = false;

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  personForm: FormGroup;
  requestUserModel: UserRequest = {};

  requestMe: MeRequest = {};

  headquarterSelected: DropdownOption;
  headquarterListOptions: DropdownOption[];

  timezoneSelected: DropdownOption;
  timezoneListOptions: DropdownOption[];

  cultureInfoSelected: DropdownOption;
  cultureInfosListOptions: DropdownOption[];

  fields: FormlyFieldConfig[];
  typeExtraFields: TypeExtraField[];

  selectedFile: File | null = null;
  srcImage!: Observable<SafeUrl>;
  bucketNameImage = 'user-profile-images';
  file = false;

  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    public bsModalRef: BsModalRef,
    private _tenantService: TenantService,
    private _usersService: UsersService,
    private _meService: MeService,
    private _newMeService: MeService,
    private _infoService: InfoService,
    private _headquartersService: HeadquartersService,
    private _extraFieldsUtilsService: ExtraFieldsUtilsService,
    private _storageUtilsService: StorageUtilsService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _communicationService: CommunicationService,
  ) {
    this.personForm = new FormGroup({
      displayName: new FormControl(null),
      userName: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      pinCode: new FormControl(null, [Validators.pattern(/^\d+$/)]),
      headquarterId: new FormControl(null),
      timezoneName: new FormControl(null),
      cultureInfoName: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.initUser();
  }

  private initUser() {
    forkJoin({
      extraFields: this._usersService.listUserFields$(),
      headquarters: this._headquartersService.listHeadquartersNew$(),
      timezones: this._infoService.getTimeZones$(),
      cultureInfos: this._infoService.getCultureInfos$(),
      tenantInfos: this._infoService.getTenantInfos(),
    })
      .pipe(
        takeUntil(this._destroy$),
        tap(({ headquarters, timezones, cultureInfos, tenantInfos }) => {
          if (headquarters && headquarters.items) {
            this.headquarterListOptions = headquarters.items.map((headquarter) => {
              return {
                value: headquarter.id,
                text: headquarter.name
              };
            });
          }

          if (timezones && timezones.length > 0) {
            this.timezoneListOptions = timezones.map((timezone) => {
              return {
                value: timezone.name,
                text: timezone.displayName
              };
            });
          }

          if (cultureInfos && cultureInfos.length > 0) {
            this.cultureInfosListOptions = cultureInfos.map((cultureInfo) => {
              return {
                value: cultureInfo.name,
                text: cultureInfo.displayName
              };
            });
          }

          if (tenantInfos) {
            this.tenantInfos = tenantInfos;
            this.personForm.get('pinCode').setValidators([
              Validators.minLength(this.tenantInfos.minUserPinCodeLength),
              Validators.maxLength(this.tenantInfos.maxUserPinCodeLength)
            ]);
          }
        }),
        concatMap(() => this.getExtraField()),
      )
      .subscribe({
        next: () => {
          if (this.isUser && !this.user) {
            this.user = {};
            this.user.extraFields = {};
            this.fields = this.typeExtraFields.map(field =>
              this._extraFieldsUtilsService.createFieldConfig(field, this.user.extraFields));
          }

          if (this.isUser && this.user?.id) {
            this.modalStatus.isPatch = true;
            this.modalStatus.isCreate = false;
            this.setUser().pipe(takeUntil(this._destroy$)).subscribe();
          }

          if (this.me && !this.user) {
            this.setMe().pipe(takeUntil(this._destroy$)).subscribe();
          }
        },
        error: error => {
          Sentry.captureEvent(error);
          this._toastrService.error(error.error.detail, error.error.title);
        }
      });
  }

  getExtraField(): Observable<void> {
    return this._usersService.listUserFields$()
      .pipe(
        takeUntil(this._destroy$),
        map(response => {
          this.typeExtraFields = response.items.map(x => x as TypeExtraField);
        })
      );
  }

  setUser(): Observable<UserModel> {
    return this._usersService.getUser$(this.tenantId, this.user.id)
      .pipe(
        takeUntil(this._destroy$),
        tap(response => {
          this.user = response;
          this.personForm.patchValue({
            displayName: response.displayName,
            userName: response.userName,
            email: response.email,
            headquarterId: response.headquarterId,
            timezoneName: response.timeZoneName,
            cultureInfoName: response.cultureInfoName,
            pinCode: response.pinCode,
          });

          this.personForm.get('userName').disable();

          if (this.headquarterListOptions && response.headquarterId) {
            let headquarter = this.headquarterListOptions.find(x => x.value == response.headquarterId);
            this.headquarterSelected = headquarter;
          }

          this.setCultureTimeZone(response);

          if (this.user) {
            this.user.extraFields = this.user.extraFields ? this.user.extraFields : {};
            this.user.extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, this.user.extraFields, false);
            this.fields = this.typeExtraFields.map(field =>
              this._extraFieldsUtilsService.createFieldConfig(field, this.user.extraFields));
          }
        }),
        finalize(() => {
          this.srcImage = this._usersService.getUserImage$(this.tenantId, this.user.id, 140, 140);
        })
      );
  }

  setMe(): Observable<Me> {
    const observable = this._meService.getMe$().pipe(
      tap(response => {
        this.me = response;
        this.personForm.patchValue({
          displayName: response.displayName,
          userName: response.userName,
          email: response.email,
          timezoneName: response.timeZoneName,
          cultureInfoName: response.cultureInfoName,
          pinCode: response.pinCode,
        });

        this.personForm.get('userName').disable();

        this.setCultureTimeZone(response);

        if (this.me) {
          this.me.extraFields = this.me.extraFields ? this.me.extraFields : {};
          this.me.extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, this.me.extraFields, false);
          this.fields = this.typeExtraFields.map(field =>
            this._extraFieldsUtilsService.createFieldConfig(field, this.me.extraFields));
        }
      })
    );

    this.srcImage = this._newMeService.getMeImage$(this.tenantId, 140, 140, true);
    return observable;
  }

  private setCultureTimeZone(response: Me | UserModel) {
    if (this.timezoneListOptions && response.timeZoneName) {
      let timezone = this.timezoneListOptions.find(x => x.value == response.timeZoneName);
      this.timezoneSelected = timezone;
    }

    if (this.cultureInfosListOptions && response.cultureInfoName) {
      let cultureInfo = this.cultureInfosListOptions.find(x => x.value == response.cultureInfoName);
      this.cultureInfoSelected = cultureInfo;
    }
  }

  onSelectedHeadquarter(event: DropdownOption) {
    this.headquarterSelected = event;
    this.personForm.patchValue({
      headquarterId: event.value
    });
  }

  onSelectedTimezone(event: DropdownOption) {
    this.timezoneSelected = event;
    this.personForm.patchValue({
      timezoneName: event.value
    });
  }

  onSelectedCultureInfos(event: DropdownOption) {
    this.cultureInfoSelected = event;
    this.personForm.patchValue({
      cultureInfoName: event.value
    });
  }

  onFileSelected($event: Event): void {
    this.selectedFile = this._storageUtilsService.getFileSelect($event);
    this.srcImage = this._storageUtilsService.onFileSelected(this.selectedFile);
  }

  onSubmit(): void {
    this._storageUtilsService.uploadFile$(this.selectedFile, this.bucketNameImage)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (this.requestUserModel && this.user) {
            this.requestUserModel.imageFileId = response?.body?.id !== undefined ? response.body.id : (this.user?.imageFileId ?? null);
          }

          if (this.requestMe && this.me) {
            this.requestMe.imageFileId = response?.body?.id !== undefined ? response.body.id : (this.me?.imageFileId ?? null);
          }

          if (this.me) {
            this.patchMe();
            return;
          }

          if (this.modalStatus.isCreate) {
            this.addUser();
          }

          if (this.modalStatus.isPatch) {
            this.patchUser();
          }
        },
        error: error => {
          this._toastrService.error(error.error.detail, error.error.title);
          Sentry.captureEvent(error);
        }
      });
  }

  addUser(): void {
    this.createSignupVectoreData();

    this._usersService.createUser$(this.tenantId, this.requestUserModel)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.id) {
            this._communicationService.sendEvent();
            this.bsModalRef.hide();
          }
        }
      });
  }

  patchUser(): void {
    this.createSignupVectoreData();

    this._usersService.updateUser$(this.tenantId, this.user.id, this.requestUserModel)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.id) {
            this._communicationService.sendEvent();
            this.bsModalRef.hide();
          }
        }
      });
  }

  patchMe(): void {
    this.createPatchMeRequest();

    this._meService.patchMe$(this.requestMe)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.id) {
            this._communicationService.sendEventToUpdateNavbar();
            this.bsModalRef.hide();
          }
        }
      });
  }

  private createSignupVectoreData(): void {
    if (this.typeExtraFields.length > 0) {
      this.requestUserModel = this._extraFieldsUtilsService.updateRequestFromForm(this.typeExtraFields, this.personForm.value, this.requestUserModel);
      this.requestUserModel.extraFields = this._extraFieldsUtilsService.parseBool(this.typeExtraFields, this.requestUserModel.extraFields);
    }

    if (!this.requestUserModel.extraFields) {
      this.requestUserModel.extraFields = {};
    }

    this.requestUserModel.email = this.personForm.get('email')?.value;
    this.requestUserModel.displayName = this.personForm.get('displayName')?.value;
    this.requestUserModel.pinCode = this.personForm.get('pinCode')?.value;
    this.requestUserModel.userName = this.personForm.get('userName')?.value;
    this.requestUserModel.headquarterId = this.personForm.get('headquarterId')?.value;
    this.requestUserModel.cultureInfoName = this.personForm.get('cultureInfoName')?.value;
    this.requestUserModel.timeZoneName = this.personForm.get('timezoneName')?.value;
  }

  private createPatchMeRequest(): void {
    if (this.typeExtraFields.length > 0) {
      this.requestMe = this._extraFieldsUtilsService.updateRequestFromForm(this.typeExtraFields, this.personForm.value, this.requestMe);
      this.requestMe.extraFields = this._extraFieldsUtilsService.parseBool(this.typeExtraFields, this.requestMe.extraFields);
    }

    this.requestMe.email = this.personForm.get('email')?.value;
    this.requestMe.displayName = this.personForm.get('displayName')?.value;
    this.requestMe.pinCode = this.personForm.get('pinCode')?.value;
    this.requestMe.cultureInfoName = this.personForm.get('cultureInfoName')?.value;
    this.requestMe.timeZoneName = this.personForm.get('timezoneName')?.value;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
