import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingModalService } from './booking-modal.service';
import { BookingModalComponent } from './booking-modal.component';
import { BookingModalStep1Component } from './booking-modal-step1/booking-modal-step1.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BookingModalFooterComponent } from './booking-modal-footer/booking-modal-footer.component';
import { BookingModalStep2Component } from './booking-modal-step2/booking-modal-step2.component';
import { BookingModalStep3Component } from './booking-modal-step3/booking-modal-step3.component';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@bluehalo/ngx-leaflet-markercluster';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BookingModalVehiclesToShowComponent } from './booking-modal-step3/booking-modal-vehicles-to-show/booking-modal-vehicles-to-show.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BookingModalStepperComponent } from './booking-modal-stepper/booking-modal-stepper.component';
import { DropdownModule } from '../../dropdown/dropdown.module';
import { StepperComponent } from 'src/app/shared/components-standalone/stepper/stepper.component';

@NgModule({
  declarations: [
    BookingModalComponent,
    BookingModalStep1Component,
    BookingModalStep2Component,
    BookingModalStep3Component,
    BookingModalVehiclesToShowComponent,
    BookingModalFooterComponent,
    BookingModalStepperComponent
  ],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    CommonModule,
    TypeaheadModule.forRoot(),
    LeafletModule,
    LeafletMarkerClusterModule,
    AutoCompleteModule,
    DropdownModule,
    StepperComponent
  ],
  providers: [
    BookingModalService
  ]
})
export class BookingModalModule { }
