import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { CultureInfo, TenantInfo, TimeZone } from '../models/info/info';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { CommonService } from './common.service';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  private _tenantId: string;

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _tenantService: TenantService
  ) {
    this._tenantId = this._tenantService.getTenantId();
  }

  getTenantInfos(): Observable<TenantInfo> {
    return this._http.get<TenantInfo>(`${VECTORE_API_BASE_URL}/${this._tenantId}/tenant-infos`)
      .pipe(
        tap({
          error: error => this._commonService.handleErrorForGetNotFound(error)
        })
      );
  }

  patchTenantInfos(tenantId: string, tenantInfo: TenantInfo): Observable<TenantInfo> {
    return this._http.patch<TenantInfo>(`${VECTORE_API_BASE_URL}/${tenantId}/tenant-infos`, tenantInfo)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  downloadCarPolicyFile$(): Observable<HttpResponse<any>> {
    return this._http.get(`${VECTORE_API_BASE_URL}/${this._tenantId}/tenant-infos/car-policy-file`,
      { observe: 'response', responseType: 'blob' })
      .pipe(
        tap(response => {
          const url = window.URL.createObjectURL(response.body);

          const a = document.createElement('a');
          a.href = url;
          a.download = 'car-policy';
          document.body.appendChild(a);
          a.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }),
        tap({
          error: error => this._commonService.handleErrorDownload(error)
        })
      );
  }

  getCultureInfos$(): Observable<CultureInfo[]> {
    return this._http.get<CultureInfo[]>(`${VECTORE_API_BASE_URL}/${this._tenantId}/culture-infos`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getTimeZones$(): Observable<TimeZone[]> {
    return this._http.get<TimeZone[]>(`${VECTORE_API_BASE_URL}/${this._tenantId}/time-zones`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listTenantExtraFields(): Observable<any> {
    return this._http.get<any>(`${VECTORE_API_BASE_URL}/${this._tenantId}/tenant-infos/extra-fields`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

}
