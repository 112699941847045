import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DropdownVerticalDotsComponent } from './dropdown-dots/dropdown-vertical-dots/dropdown-vertical-dots.component';
import { DropdownPrimaryMakeComponent } from './dropdown-primary/dropdown-primary-make/dropdown-primary-make.component';
import { DropdownPrimaryHeadquarterComponent } from './dropdown-primary/dropdown-primary-headquarter/dropdown-primary-headquarter.component';
import { DropdownPrimaryLocationComponent } from './dropdown-primary/dropdown-primary-location/dropdown-primary-location.component';
import { DropdownOrizontalDotsComponent } from './dropdown-dots/dropdown-orizontal-dots/dropdown-orizontal-dots.component';
import { DropdownDynamicComponent } from './dropdown-dynamic/dropdown-dynamic.component';
import { DropdownPrimaryComponent } from './dropdown-primary/dropdown-primary.component';
import { DropdownPrimaryBookingTypeComponent } from './dropdown-primary/dropdown-primary-booking-type/dropdown-primary-booking-type.component';
import { DropdownPrimaryPurchaseTypeComponent } from './dropdown-primary/dropdown-primary-purchase-type/dropdown-primary-purchase-type.component';
import { DropdownStaticPrimaryComponent } from './dropdown-static-primary/dropdown-static-primary.component';
import { DropdownPrimaryTaskTypeComponent } from './dropdown-primary/dropdown-primary-task-type/dropdown-primary-task-type.component';
import { DropdownPrimaryFuelTypeComponent } from './dropdown-primary/dropdown-primary-fuel-type/dropdown-primary-fuel-type.component';
import { DropdownPrimaryPermissionComponent } from './dropdown-primary/dropdown-primary-permission/dropdown-primary-permission.component';
import { DropdownPrimaryModelComponent } from './dropdown-primary/dropdown-primary-model/dropdown-primary-model.component';
import { DropdownPrimaryAssetTypeComponent } from './dropdown-primary/dropdown-primary-asset-type/dropdown-primary-asset-type.component';
import { DropdownPrimaryVehicleTypeComponent } from './dropdown-primary/dropdown-primary-vehicle-type/dropdown-primary-vehicle-type.component';
import { DropdownPrimaryActiveComponent } from './dropdown-primary/dropdown-primary-active/dropdown-primary-active.component';

@NgModule({
  declarations: [    
    DropdownPrimaryComponent,
    DropdownDynamicComponent,
    DropdownOrizontalDotsComponent,
    DropdownPrimaryLocationComponent,
    DropdownPrimaryHeadquarterComponent,
    DropdownPrimaryPermissionComponent,
    DropdownPrimaryMakeComponent,
    DropdownPrimaryModelComponent,
    DropdownPrimaryFuelTypeComponent,
    DropdownPrimaryVehicleTypeComponent,
    DropdownPrimaryTaskTypeComponent,
    DropdownVerticalDotsComponent,
    DropdownStaticPrimaryComponent,
    DropdownPrimaryPurchaseTypeComponent,
    DropdownPrimaryAssetTypeComponent,
    DropdownPrimaryBookingTypeComponent,
    DropdownPrimaryActiveComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
  ],
  exports: [
    DropdownPrimaryComponent,
    DropdownDynamicComponent,
    DropdownOrizontalDotsComponent,
    DropdownPrimaryLocationComponent,
    DropdownPrimaryHeadquarterComponent,
    DropdownPrimaryPermissionComponent,
    DropdownPrimaryMakeComponent,
    DropdownPrimaryModelComponent,
    DropdownPrimaryFuelTypeComponent,
    DropdownPrimaryVehicleTypeComponent,
    DropdownPrimaryTaskTypeComponent,
    DropdownVerticalDotsComponent,
    DropdownStaticPrimaryComponent,
    DropdownPrimaryPurchaseTypeComponent,
    DropdownPrimaryAssetTypeComponent,
    DropdownPrimaryBookingTypeComponent,
    DropdownPrimaryActiveComponent,
  ]
})
export class DropdownModule { }

