import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterDateService {

  constructor() { }

  getStartAndEndOfDay(date = new Date()) {
    const startOfDay = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    const endOfDay = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999));

    const startDate = startOfDay.toISOString();
    const endDate = endOfDay.toISOString();

    return { startDate, endDate };
  }

  getStartAndEndOfWeek(date = new Date()) {
    const dayOfWeek = date.getDay();
    const differenceToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;

    const startDateT = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + differenceToMonday));
    const endDateT = new Date(Date.UTC(startDateT.getFullYear(), startDateT.getMonth(), startDateT.getDate() + 6, 23, 59, 59, 999));

    const startDate = startDateT.toISOString();
    const endDate = endDateT.toISOString();

    return { startDate, endDate };
  }
  
  getStartAndEndOfMonth(date = new Date()) {
    const startDateT = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
    const endDateT = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999));

    const startDate = startDateT.toISOString();
    const endDate = endDateT.toISOString();

    return { startDate, endDate };
  }
  
  getStartAndEndOfYear(date = new Date()) {
    const startOfYear = Date.UTC(date.getFullYear(), 0, 1, 0, 0, 0);
    const endOfYear = Date.UTC(date.getFullYear(), 11, 31, 23, 59, 59, 999);

    const startDateT = new Date(startOfYear);
    const endDateT = new Date(endOfYear);

    const startDate = startDateT.toISOString();
    const endDate = endDateT.toISOString();

    return { startDate, endDate };
  }

  getStartEndDateByTypeRange(dateRange : string) : { startDate: string, endDate: string } | null {
    const now = new Date();

    if (dateRange == 'today') {
      return this.getStartAndEndOfDay(now);
    } 
    
    if (dateRange == 'this_week') {
      return this.getStartAndEndOfWeek(now);
    }

    if (dateRange == 'this_month') {
      return this.getStartAndEndOfMonth(now);
    }

    if (dateRange == 'this_year') {
      return this.getStartAndEndOfYear(now);
    }

    if (dateRange == 'all_time') {
      return { startDate: null, endDate: null };
    }

    return null;
  }

  getStartEndDatePrev(dateRange: string) : { startDate: string, endDate: string } | null {
    if (dateRange == 'today') {
      const now = new Date();
      const yesterday = new Date(now.setDate(now.getDate() - 1));
      return this.getStartAndEndOfDay(yesterday);
    }

    if (dateRange == 'this_week') {
      const now = new Date();
      const pastWeek = new Date(now.setDate(now.getDate() - now.getDay()));
      return this.getStartAndEndOfWeek(pastWeek);
    }

    if (dateRange == 'this_month') {
      const now = new Date();
      const pastMonth = new Date(now.setMonth(now.getMonth() - 1));
      return this.getStartAndEndOfMonth(pastMonth);
    }

    if (dateRange == 'this_year') {
      const now = new Date();
      const pastYear = new Date(now.setFullYear(now.getFullYear() - 1));
      return this.getStartAndEndOfYear(pastYear);
    }

    if (dateRange == 'all_time') {
      return { startDate: null, endDate: null };
    }

    return null;
  }
}
