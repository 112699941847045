import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { ModalUtilsService } from '../../shared/utilities/modal-utils.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as Modals from 'src/app/shared/constant/modals';

@Injectable({
  providedIn: 'root'
})
export class CentralQueryParamsService {

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _modalUtilsService: ModalUtilsService,
    private _router: Router,
  ) {
    this.subscribeToQueryParams();
  }

  private subscribeToQueryParams() {
    this._activatedRoute.queryParams.subscribe(params => {
      const taskId = params[Modals.EDIT_TASK_MODAL] as string;
      if (taskId) {
        this.openEditTaskModal(taskId);
      }

      const bookingId = params[Modals.FRINGE_BENEFIT_MODAL] as string;
      if (bookingId) {
        this.openEditFringeBenefitModal(bookingId);
      }

      const createTaskModal = params[Modals.CREATE_TASK_MODAL] as string;
      if (createTaskModal) {
        this.openCreateTaskModal();
      }

      const createMyBookingModal = params[Modals.CREATE_MY_BOOKING_MODAL] as string;
      if (createMyBookingModal) {
        this.openCreateBookingModal();
      }

      const assistanceModal = params[Modals.ASSISTANCE_MODAL] as string;
      if (assistanceModal) {
        let subscription = this._modalUtilsService.openAssistanceModal().pipe(
          switchMap(modal => this.handleModalEvent(modal, Modals.ASSISTANCE_MODAL))
        ).subscribe({
          complete: () => {
            subscription.unsubscribe();
          }
        });
      }
    });
  }

  private openCreateTaskModal() {
    let subscription = this._modalUtilsService.openCreateTaskModal().pipe(
      switchMap(modal => this.handleModalEvent(modal, Modals.CREATE_TASK_MODAL))
    ).subscribe({
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

  private openEditTaskModal(taskId: string) {
    let subscription = this._modalUtilsService.openEditTaskModal(taskId).pipe(
      switchMap(modal => this.handleModalEvent(modal, Modals.CREATE_TASK_MODAL))
    ).subscribe({
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

  private openEditFringeBenefitModal(bookingId: string) {
    let subscription = this._modalUtilsService.openFringeBenefitModal(bookingId).pipe(
      switchMap(modal => this.handleModalEvent(modal, Modals.FRINGE_BENEFIT_MODAL))
    ).subscribe({
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

  private openCreateBookingModal() {
    let subscription = this._modalUtilsService.openBookingModal().pipe(
      switchMap(modal => this.handleModalEvent(modal, Modals.CREATE_MY_BOOKING_MODAL))
    ).subscribe({
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

  private handleModalEvent(modal: BsModalRef<any>, key: string) {
    if (!modal) {
      return of(null);
    }

    return modal.onHide.pipe(
      tap(() => {
        this._router.navigate([], {
          queryParams: { [key]: null }
        })
      }));
  }

  addQueryParam(key: string, value: string) : void {
    this._router.navigate([], {
      queryParams: { [key]: value }
    });
  }

  removeQueryParam(key: string) : void {
    this._router.navigate([], {
      queryParams: { [key]: null }
    });
  }

  getQueryParam$(key: string) : Observable<string | null> {
    return this._activatedRoute.queryParams.pipe(
      map((params: any) => params[key] || null)
    );
  }
}
