import { Component } from '@angular/core';
import { BookingModalService } from '../booking-modal.service';

@Component({
  selector: 'booking-modal-stepper',
  template: `
    <stepper [steps]="steps" [currentStep]="currentStep"></stepper>
  `,
})
export class BookingModalStepperComponent {

  get currentStep() {
    return this._bookingModalService.currentStep;
  }

  get showStepMap() {
    return this._bookingModalService.showStepMap;
  }

  get steps() {
    return this._bookingModalService.bookingModalStepper;
  }

  constructor(private _bookingModalService: BookingModalService) { }
  
  ngOnInit() {
  }
}
