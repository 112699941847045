import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { inject } from '@angular/core';
import { AccessUtilsService } from 'src/app/shared/utilities/access-utils.servic';
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { MeService } from 'src/app/shared/api-services/me.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

export function authGuardFn(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
  const authService = inject(AuthenticationService);
  const accessService = inject(AccessUtilsService);
  const meService = inject(MeService);
  const tenantService = inject(TenantService);
  const translateService = inject(TranslateService);
  const toastrService = inject(ToastrService);
  const tenantId = tenantService.getTenantId();

  authService.checkLoop();

  return authService.checkAuthentication().pipe(
    switchMap(isAuthenticated => {
      if (!isAuthenticated) {
        if (route.routeConfig.path !== RoutesUrl.LOGIN) {
          let saveUrl = {
            url: route.routeConfig.path,
            queryParams: route.queryParams,
          };
          authService.saveUrl = saveUrl;
        }
        return of(authService.router.createUrlTree([RoutesUrl.LOGIN]));
      }

      if (authService.saveUrl) {
        let saveUrl = authService.saveUrl;
        authService.saveUrl = null;
        return of(authService.router.createUrlTree([saveUrl.url], { queryParams: saveUrl.queryParams }));
      }

      if (route.routeConfig?.path === RoutesUrl.REGISTRATION) {
        return of(true);
      }

      return meService.getMe$().pipe(
        switchMap(me => {
          if (!me) {
            return of(authService.router.createUrlTree([RoutesUrl.REGISTRATION]));
          }
          return of(true)
          // const accessPages = accessService.getAllAcessPages(me);
          // if (accessPages.includes(route.routeConfig?.path)) {
          //   return of(true);
          // } else {
          //   toastrService.error(translateService.instant('MESSAGES.NO_ACCESS_MESSAGE'), translateService.instant('MESSAGES.NO_ACCESS_TITLE'));
          //   return of(authService.router.createUrlTree([RoutesUrl.HOME]));
          // }
        }),
        catchError(error => {
          if (error.status === 404) {
            toastrService.error(translateService.instant('MESSAGES.USER_NOT_FOUND'));
            return of(authService.router.createUrlTree([RoutesUrl.REGISTRATION]));
          }
          toastrService.error(error.error.message, error.error.title);
          return of(authService.router.createUrlTree([RoutesUrl.LOGIN]));
        })
      );
    })
  );
}

export function authGuardModal(authService: AuthenticationService): Observable<boolean | UrlTree> {
  return authService.checkAuthentication().pipe(
    switchMap(isAuthenticated => {
      if (!isAuthenticated) {
        return of(authService.router.createUrlTree([RoutesUrl.LOGIN]));
      }
      return of(true);
    })
  );
}