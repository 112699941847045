import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';
import { Subject, takeUntil } from 'rxjs';
import { ImportService } from 'src/app/shared/api-services/import.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilterModalService } from '../filter-modal/filter-modal.service';
import { GenericFilter } from '../filter-modal/generic-filter';
import { ImportStatus } from 'src/app/shared/constant/status-import';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

export const filterImportFileModal = 'filter-import-file-modal';

@Component({
  selector: 'import-file-filter-modal',
  templateUrl: './import-file-filter-modal.component.html',
  styleUrl: './import-file-filter-modal.component.scss'
})
export class ImportFileFilterModalComponent implements OnInit, OnDestroy {
  form: FormGroup;
  genericFilter: GenericFilter = {};

  typeImportersOption: DropdownOption[];
  selectedTypeImporter: DropdownOption = { value: '', text: this._translateService.instant('SELECT_OPTION') };

  statusOption: DropdownOption[];
  selectedStatus: DropdownOption = { value: '', text: this._translateService.instant('SELECT_OPTION') };

  private _destroy = new Subject<void>();

  constructor(
    private _importService: ImportService,
    private _filterModalService: FilterModalService,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
    public bsModalRef: BsModalRef
  ) {
    this.form = new FormGroup({
      importerName: new FormControl(null),
      status: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.getImporters();
    this.getStatus();
  }

  private getStatus() {
    this.statusOption = Object.values(ImportStatus).map(status => ({ value: status, text: this._translateService.instant(status) }));
  }

  private getImporters() {
    this._importService.listImporters$().pipe(
      takeUntil(this._destroy)
    ).subscribe({
      next: response => {
        this.typeImportersOption = response.items.map(importer => ({ value: importer.name, text: importer.name }));
      }
    });
  }

  onSelectedTypeImporter($event: DropdownOption) {
    this.form.patchValue({ importerName: $event.value });
    this.genericFilter = this.genericFilter ?? {};
    this.genericFilter.importerName = $event.value;
  }

  onSelectedStatus($event: DropdownOption) {
    this.form.patchValue({ status: $event.value });
    this.genericFilter = this.genericFilter ?? {};
    this.genericFilter.statusImportFile = $event.value as ImportStatus;
  }

  apply() {
    this._toastrService.success(this._translateService.instant('FILTER_APPLYED'));
    this._filterModalService.applyFilter(filterImportFileModal, this.genericFilter);
    this.bsModalRef.hide();
    this.genericFilter = {};
  }

  close() {
    this.form.reset();
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
