import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { CreateTaskRequest } from 'src/app/shared/models/task/task-request';

@Component({
  selector: 'task-end-mileage',
  templateUrl: './task-end-mileage.component.html',
  styleUrl: './task-end-mileage.component.scss'
})
export class TaskEndMileageComponent implements OnInit, OnDestroy {

  
  @Input() taskRequest: CreateTaskRequest;
  @Output() taskRequestChange = new EventEmitter<CreateTaskRequest>();
  
  form: FormGroup;
  private _destroy$: Subject<void> = new Subject<void>();
  
  constructor() {
    this.form = new FormGroup({
      mileage: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.form.patchValue({
      mileage: this.taskRequest.endMileage,
    });

    this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      this.taskRequestChange.emit({
        ...this.taskRequest,
        endMileage: value.mileage,
      });
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
