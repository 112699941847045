import { Component } from '@angular/core';
import { BookingClient, BookingUsage } from 'src/app/shared/models/booking/booking';
import { BookingService } from 'src/app/shared/api-services/booking.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { ActivatedRoute } from "@angular/router";
import { BookingUtilsService } from "src/app/shared/utilities/booking-utils.service";
import { BookingUsageMileageModalComponent } from "src/app/shared/components/modals/booking-usage-mileage-modal/booking-usage-mileage-modal.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { DropdownOption } from "../../dropdown/dropdown-primary/dropdown.interface";
import { TranslateService } from "@ngx-translate/core";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { VehicleUsagesFilter } from 'src/app/shared/models/vehicle-usages/vehicle-usage-filter';
import { CentralQueryParamsService } from 'src/app/core/services/central-query-params.service';
import { BookingUsageResponse } from 'src/app/shared/models/booking/booking-response';

@Component({
  selector: 'booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.scss'],
})


export class BookingDetailComponent {

  tenantId = this._tenantService.getTenantId();
  bookingId: string;
  vehicleUsageFilter: VehicleUsagesFilter = {};
  isFleetManager: boolean = false;

  booking$: Observable<BookingClient>;
  bookingUsage: BookingUsage;
  listBookingUsage: BookingUsageResponse;

  modalUsageMileage: any;

  nowUtc: string;

  listTableFilterTasks!: DropdownOption[];

  private destroy$ = new Subject<void>();

  constructor(
    private _activeRoute: ActivatedRoute,
    private _modalService: BsModalService,
    private _tenantService: TenantService,
    private _bookingService: BookingService,
    private _communicationService: CommunicationService,
    private _bookingUtilsService: BookingUtilsService,
    private _translateService: TranslateService,
    private _centralQueryParamsService: CentralQueryParamsService
  ) {
    this.listTableFilterTasks = this._translateService.instant('TASKS.SECTION5.DYNAMIC_TABLE.FILTERS.FILTER_TASKS_BOOKING');
  }

  ngOnInit() {
    this._activeRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.bookingId = params['id'] || null;
        this.vehicleUsageFilter.bookingId = this.bookingId;
        this.getBooking();
        this.getBookingUsage();
      });


    this.bookingId = this._activeRoute.snapshot.paramMap.get('id') || null;
    this.vehicleUsageFilter.bookingId = this.bookingId;

    if (window.location.href.indexOf("fleetmanager-booking") > -1) {
      this.isFleetManager = true;
    }

    this.getBooking();
    this.getBookingUsage();

    this._communicationService.getEvent()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.getBooking();
          this.getBookingUsage();
        }
      });

  }

  openModalMileage() {
    this.modalUsageMileage = this._modalService.show(BookingUsageMileageModalComponent, {
      class: 'modal-sm',
      animated: true,
      initialState: {
        bookingUsage: this.bookingUsage,
        bookingId: this.bookingId
      }
    });

    this.modalUsageMileage.onHidden
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getBooking();
        this.getBookingUsage();
      });
  }

  getBooking() {
    let params = {
      includeUser: true,
      includeVehicle: true,
      includeLocation: true,
      includeHeadquarter: true,
      includeBookingType: true,
      includeInsertedByUser: true,
    };

    this.booking$ = this._bookingService.getBooking$(this.bookingId, params)
      .pipe(
        tap(response => {
          response = this._bookingUtilsService.setStatusBadgeBookingInDetail(response);
        })
      );
  }

  getBookingUsage() {
    this._bookingService.listBookingUsages$(this.tenantId, this.bookingId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (bookingUsagesResponse: BookingUsageResponse) => {
          this.listBookingUsage = bookingUsagesResponse;
          if (this.listBookingUsage.count > 0) {
            let last = this.listBookingUsage.count - 1;
            this.bookingUsage = this.listBookingUsage.items[last];
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}