import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown-primary/dropdown.interface';
import { UserFilterClient, UsersFilter } from 'src/app/shared/models/user/user-filter';
import { ModalUtilsService } from 'src/app/shared/utilities/modal-utils.service';
import { GenericFilter } from '../../../modals/filter-modal/generic-filter';
import { FilterModalService } from '../../../modals/filter-modal/filter-modal.service';
import { filterPersonModal } from '../../../modals/filter-modal/filter-person-modal/filter-person-modal.component';
import { Tag } from 'src/app/shared/models/tag/tag';
import { UserGroup } from 'src/app/shared/models/user-group/user-group';

@Component({
  selector: 'persons-table-filters',
  templateUrl: './persons-table-filters.component.html',
  styleUrls: ['./persons-table-filters.component.scss']
})
export class PersonsTableFiltersComponent {
  @Input() titleTable: string;
  @Input() hasManageUserPermission: boolean = false;

  @Input() userFilter: UserFilterClient = {};

  listTableFilterHeaders!: DropdownOption[];
  filterHeader: DropdownOption;

  listMassiveActions: DropdownOption[];

  filterForm: FormGroup = new FormGroup({
    headerToFilter: new FormControl(),
    inputFilter: new FormControl(),
    startDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/)),
    endDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/))
  });

  @Output() filterTableHeaderChanged = new EventEmitter<FormGroup>();
  @Output() onFilterModalHeaderChanged = new EventEmitter<UsersFilter>();
  @Output() exportEvent = new EventEmitter<any>();
  @Output() onMassiveAction = new EventEmitter<string>();

  private _destroy$ = new Subject<void>();

  constructor(
    private _translateService: TranslateService,
    private _modalUtilsService: ModalUtilsService,
    private _filterModalService: FilterModalService
  ) { }

  ngOnInit(): void {
    this.listTableFilterHeaders = this._translateService.instant('PERSONS.FILTERS.HEADERS');
    this.filterHeader = JSON.parse(JSON.stringify(this.listTableFilterHeaders[0]));

    this.listMassiveActions = this._translateService.instant('MASSIVE_ACTIONS');

    this.autoComplete();
    this.getFilter();
  }

  private getFilter() {
    this._filterModalService.getFilter$(filterPersonModal)
      .pipe(takeUntil(this._destroy$))
      .subscribe((filter: GenericFilter) => {
        if (filter?.headquarter) {
          this.userFilter.headquarterId = filter?.headquarter?.id ?? null;
          this.userFilter.headquarter = filter?.headquarter;
        }

        if (filter?.tag) {
          if (!this.userFilter.tagIds || this.userFilter.tagIds.length === 0) {
            this.userFilter.tagIds = [filter?.tag?.id];
          } else {
            if (!this.userFilter.tagIds.includes(filter?.tag?.id)) {
              this.userFilter.tagIds = [...this.userFilter.tagIds, filter?.tag?.id];
            }
          }
          if (!this.userFilter.tags || this.userFilter.tags.length === 0) {
            this.userFilter.tags = [filter?.tag];
          } else {
            if (!this.userFilter.tags.includes(filter?.tag)) {
              this.userFilter.tags = [...this.userFilter.tags, filter?.tag];
            }
          }
        }

        if (filter?.group) {
          if (!this.userFilter.groupIds || this.userFilter.groupIds.length === 0) {
            this.userFilter.groupIds = [filter?.group?.id];
          } else {
            if (!this.userFilter.groupIds.includes(filter?.group?.id)) {
              this.userFilter.groupIds = [...this.userFilter.groupIds, filter?.group?.id];
            }
          }
          if (!this.userFilter.groups || this.userFilter.groups.length === 0) {
            this.userFilter.groups = [filter?.group];
          } else {
            if (!this.userFilter.groups.find((group: UserGroup) => group.id === filter?.group?.id)) {
              this.userFilter.groups = [...this.userFilter.groups, filter?.group];
            }
          }
        }

        if (filter?.hasGroups !== undefined) {
          this.userFilter.hasGroups = filter?.hasGroups;
        }
        
        this.onFilterModalHeaderChanged.emit(this.userFilter);
      });
  }

  private autoComplete() {
    this.filterForm.patchValue({ headerToFilter: this.filterHeader.value });
    this.filterForm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.filterTableHeaderChanged.emit(this.filterForm);
      });
  }

  removeAllFilters() {
    this.userFilter = {};
    this.onFilterModalHeaderChanged.emit(this.userFilter);
  }

  removeHasBoolean(filter: keyof UserFilterClient) {
    this.userFilter[filter] = null;
    this.onFilterModalHeaderChanged.emit(this.userFilter);
  }

  removeFilter(
    filterModel: keyof UserFilterClient,
    filterKey: keyof UserFilterClient
  ) {
    this.userFilter[filterModel] = null;
    this.userFilter[filterKey] = null;
    this.onFilterModalHeaderChanged.emit(this.userFilter);
  }

  removeFilterArray(
    filterModel: keyof UserFilterClient,
    filterKey: keyof UserFilterClient,
    id: string,
  ) {
    this.userFilter[filterModel].splice(this.userFilter[filterModel].findIndex((tag: Tag) => tag.id === id), 1);
    this.userFilter[filterKey].splice(this.userFilter[filterKey].findIndex((tagId: string) => tagId === id), 1);
    this.onFilterModalHeaderChanged.emit(this.userFilter);
  }

  selectedOption($event: DropdownOption) {
    this.filterHeader = $event;
    this.filterForm.patchValue({ headerToFilter: $event.value });
  }

  selectMassiveOption($event: DropdownOption) {
    this.onMassiveAction.emit($event.value);
  }

  onFilterClick() {
    this._modalUtilsService.openPersonFilterModal()
      .pipe(takeUntil(this._destroy$))
      .subscribe();
  }

  onClickExport() {
    this.exportEvent.emit();
  }
}
