<div class="card-filter-task">
    <header class="d-flex justify-content-between align-items-center">
        <h4 class="card-person-modal-title">
            {{ 'FILTER.CREATE_FILTER' | translate }}
        </h4>
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <div class="content">
        <form class="d-flex flex-column gap-3" [formGroup]="form">

            <div class="form-group">
                <label for="task-type" class="form-label">{{'TYPOLOGY' | translate}}</label>
                <dropdown-primary-vehicle-type 
                    [vehicleTypeId]="form.value.vehicleTypeId ?? null"
                    (onSelectedEvent)="onVehicleTypeSelected($event)">                    
                </dropdown-primary-vehicle-type>
            </div>

            <div class="form-group">
                <label for="purchase-type" class="form-label">{{'VEHICLES.PURCHASE_TYPE' | translate}}</label>
                <dropdown-primary-purchase-type 
                    [purchaseTypeId]="form.value.purchaseTypeId ?? null"
                    (onSelectedEvent)="onPurchaseTypeSelected($event)">                    
                </dropdown-primary-purchase-type>
            </div>

            <div class="form-group">
                <label for="fuel-type" class="form-label">{{'VEHICLES.FUEL_TYPE' | translate}}</label>
                <dropdown-primary-fuel-type 
                    [fuelTypeId]="form.value.fuelTypeId ?? null"
                    (onSelectedEvent)="onFuelTypeSelected($event)">                    
                </dropdown-primary-fuel-type>
            </div>

            <div class="form-group">
                <label for="make" class="form-label">{{'VEHICLES.MAKE' | translate}}</label>
                <input type="text" class="form-control h-100" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="makeName"
                    [isAnimated]="true"
                    [typeahead]="makeName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectMake($event)">
            </div>

            <div class="form-group">
                <label for="model" class="form-label">{{'VEHICLES.MODEL' | translate}}</label>
                <input type="text" class="form-control h-100" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="modelName"
                    [isAnimated]="true"
                    [typeahead]="modelName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectModel($event)">
            </div>

            <div class="form-group">
                <label for="version" class="form-label">{{'VEHICLES.VERSION' | translate}}</label>
                <input type="text" class="form-control h-100" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="versionName"
                    [isAnimated]="true"
                    [typeahead]="versionName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectVersion($event)">
            </div>

            <div class="form-group">
                <label for="license-plate" class="form-label">{{'HEADQUARTER.NAME' | translate}}</label>
                <input type="text" class="form-control h-100" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="headquarterName"
                    [isAnimated]="true"
                    [typeahead]="headquarterName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectHeadquarter($event)">
            </div>

            <div class="form-group">
                <label for="license-plate" class="form-label">{{'LOCATION.NAME' | translate}}</label>
                <input type="text" class="form-control h-100" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="locationName"
                    [isAnimated]="true"
                    [typeahead]="locationName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectLocation($event)">
            </div>

            <div class="form-group">
                <label for="tag" class="form-label">{{'TAG' | translate}}</label>
                <input type="text" class="form-control h-100" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="tagName"
                    [isAnimated]="true"
                    [typeahead]="tagName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectTag($event)">
            </div>

            <div class="form-group">
                <label class="form-label" for="flexSwitchCheckDefault">{{'IS_EXIT' | translate}}</label>
                <dropdown-primary-active (onSelectedEvent)="onSelectedIsExit($event)"></dropdown-primary-active>
            </div>

            <div class="form-group">
                <label class="form-label" for="flexSwitchCheckDefault">{{'IS_SUSPENDED' | translate}}</label>
                <dropdown-primary-active (onSelectedEvent)="onSelectedIsSuspended($event)"></dropdown-primary-active>
            </div>

            <div class="form-group d-flex gap-2">
                <input type="checkbox" class="form-check-input" id="closeOnApply" formControlName="closeOnApply">
                <label for="closeOnApply" class="form-check-label">{{'CLOSE_FILTER_ON_APPLY' | translate}}</label>
            </div>

        </form>
    </div>

    <footer class="d-flex gap-2 align-items-center justify-content-center mt-4">
        <button type="button" class="btn-gs-secondary" (click)="close()">{{'ACTIONS.CLOSE' | translate}}</button>
        <button type="button" class="btn-gs-primary" (click)="apply()">{{'ACTIONS.APPLY' | translate}}</button>
    </footer>
</div>