<div class="filters mb-3">
    <div class="filters-section-left btn-group">
        <h3 class="table-title">{{ titleTable | translate}}</h3>
    </div>

    <div class="filters-section-right">
      <form [formGroup]="filterForm">
  
        <div class="input-group d-flex gap-1">
  
          <dropdown-primary 
            [(selectedOption)]="filterHeader"
            [emitNgOnInit]="false"
            [options]="listTableFilterHeaders"
            (selectedOptionChange)="selectedOption($event)"
            [iconClass]="'bi bi-chevron-down'"
            [cssClass]="'btn-lightgray rounded-1'">
          </dropdown-primary>
  
          <input type="text" class="form-control rounded-1" formControlName="inputFilter">
  
        </div>
      </form>

      <button-first-type 
          [cssClass]="'btn-gs-secondary-icon'"
          [iconClass]="'bi bi-funnel'" 
          [buttonText]= "'FILTERR'"
          (click)="onFilterClick()">
      </button-first-type>
      
      <button-first-type 
          [cssClass]="'btn-gs-secondary-icon'"
          [iconClass]="'bi bi-download'" 
          [buttonText]= "'EXPORT' | translate"
          (click)="onClickExport()">
      </button-first-type>

      @if (hasManageVehiclePermission) {
        <dropdown-primary 
          [emitNgOnInit]="false"
          [selectedOption]="{ value: '', text: 'ACTIONS.NAME' | translate }"
          [options]="listMassiveActions"
          [isStaticSelectOption] = "true"
          (selectedOptionChange)="selectMassiveOption($event)"
          [iconClass]="'bi bi-three-dots-vertical'"
          [cssClass]="'btn-gs-primary-icon flex-row-reverse'">
        </dropdown-primary>
      }
    </div>  
</div>

@if (vehicleFilter?.headquarter || 
      (vehicleFilter?.location) ||
      (vehicleFilter?.vehicleType) ||
      (vehicleFilter?.purchaseType) ||
      (vehicleFilter?.fuelType) ||
      (vehicleFilter?.make) ||
      (vehicleFilter?.model) ||
      (vehicleFilter?.version) ||
      (vehicleFilter?.isExit !== undefined) ||
      (vehicleFilter?.isSuspended !== undefined) ||
      (vehicleFilter?.tags && vehicleFilter?.tags.length > 0)) {
  <div class="container-filters d-flex gap-3 align-items-center justify-content-end mb-3">

    @if (vehicleFilter?.headquarter) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'HEADQUARTER.NAME' | translate }}: {{vehicleFilter?.headquarter?.name}}</span>
        <i class="bi bi-x" (click)="removeFilter('headquarter', 'headquarterId')"></i>
      </button>
    }

    @if (vehicleFilter?.location) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'LOCATION.NAME' | translate }}: {{vehicleFilter?.location?.name}}</span>
        <i class="bi bi-x" (click)="removeFilter('location', 'locationId')"></i>
      </button>
    }

    @if (vehicleFilter?.tags && vehicleFilter?.tags?.length > 0) {
      <button class="btn-gs-filter" *ngFor="let tag of vehicleFilter?.tags">
        <i class="bi bi-funnel"></i>
        <span>{{ 'TAG' | translate }}: {{tag?.name}}</span>
        <i class="bi bi-x" (click)="removeFilterArray('tags', 'tagIds', tag?.id)"></i>
      </button>
    } 

    @if (vehicleFilter?.isExit !== undefined) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'IS_EXIT' | translate }}: {{vehicleFilter?.isExit ? ('ACTIONS.YES' | translate) : ('ACTIONS.NO' | translate)}}</span>
        <i class="bi bi-x" (click)="removeHasBoolean('isExit')"></i>
      </button>
    }

    @if (vehicleFilter?.isSuspended !== undefined) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'IS_SUSPENDED' | translate }}: {{vehicleFilter?.isSuspended ? ('ACTIONS.YES' | translate) : ('ACTIONS.NO' | translate)}}</span>
        <i class="bi bi-x" (click)="removeHasBoolean('isSuspended')"></i>
      </button>
    }

    @if (vehicleFilter?.make) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'VEHICLES.MAKE' | translate }}: {{vehicleFilter?.make?.name}}</span>
        <i class="bi bi-x" (click)="removeFilter('make', 'makeId')"></i>
      </button>
    }

    @if (vehicleFilter?.model) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'VEHICLES.MODEL' | translate }}: {{vehicleFilter?.model?.name}}</span>
        <i class="bi bi-x" (click)="removeFilter('model', 'modelId')"></i>
      </button>
    }

    @if (vehicleFilter?.version) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'VEHICLES.VERSION' | translate }}: {{vehicleFilter?.version?.name}}</span>
        <i class="bi bi-x" (click)="removeFilter('version', 'versionId')"></i>
      </button>
    }

    @if (vehicleFilter?.vehicleType) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'VEHICLES.VEHICLE_TYPE' | translate }}: {{vehicleFilter?.vehicleType?.name}}</span>
        <i class="bi bi-x" (click)="removeFilter('vehicleType', 'vehicleTypeId')"></i>
      </button>
    }

    @if (vehicleFilter?.fuelType) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'VEHICLES.FUEL_TYPE' | translate }}: {{vehicleFilter?.fuelType?.name}}</span>
        <i class="bi bi-x" (click)="removeFilter('fuelType', 'fuelTypeId')"></i>
      </button>
    }

    @if (vehicleFilter?.purchaseType) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'VEHICLES.PURCHASE_TYPE' | translate }}: {{vehicleFilter?.purchaseType?.name}}</span>
        <i class="bi bi-x" (click)="removeFilter('purchaseType', 'purchaseTypeId')"></i>
      </button>
    }

    <button class="btn-gs-secondary-trash" (click)="removeAllFilters()">
      <i class="bi bi-trash"></i>
    </button>
    
  </div>
}

