import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BookingModalService } from './booking-modal.service';
import { Booking } from 'src/app/shared/models/booking/booking';
import { trigger, transition, style } from '@angular/animations';
import { animate } from '@angular/animations';

export interface BookingModalNewStatus {
  id: number;
  title: string;
  component: any;
}

@Component({
  selector: 'booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrl: './booking-modal.component.scss',
  animations: [
    trigger('slideAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('200ms ease-out', style({ transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class BookingModalComponent {

  @Input() booking: Booking;
  @Input() canBookForOthers: boolean = false;

  get currentStep() {
    return this._bookingModalNewService.currentStep;
  }
  
  get bookingModalStatus() {
    return this._bookingModalNewService.bookingModalStatus;
  }

  constructor(
    public bsModalRef: BsModalRef,
    private _bookingModalNewService: BookingModalService
  ) { }

  ngOnInit(): void {
    this._bookingModalNewService.onInitModal();
    this._bookingModalNewService.setCanDoBookingForOtherUsers(this.canBookForOthers);
    if (this.booking) {
      this._bookingModalNewService.setBooking(this.booking);
    }
    this._bookingModalNewService.setBsModalRef(this.bsModalRef);
  }

  closeModal() {
    this._bookingModalNewService.clearAll();
    this.bsModalRef.hide();
  }
}
