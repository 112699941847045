import * as Sentry from "@sentry/angular-ivy";
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { Observable, Observer, Subject, debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from "rxjs";
import { Tag } from "src/app/shared/models/tag/tag";
import { TagsService } from "src/app/shared/api-services/tags.service";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { TypeExtraField } from "src/app/shared/models/common";
import { ExtraFieldsUtilsService } from "src/app/shared/utilities/extra-fields-utils.servic";
import { Supplier } from "src/app/shared/models/supplier/supplier";
import { Invoice } from "src/app/shared/models/invoice/invoice";
import { InvoiceFileRequest, InvoiceRequest } from "src/app/shared/models/invoice/invoice-request";
import { InvoicesService } from "src/app/shared/api-services/invoices.service";
import { SuppliersService } from "src/app/shared/api-services/suppliers.service";
import { DocumentResponse, StorageFile } from "src/app/shared/models/storage";
import { TasksService } from "src/app/shared/api-services/tasks.service";
import { TasksFilter, TaskInvoicesFilter } from "src/app/shared/models/task/task-filter";
import { Task, TaskInvoice } from "src/app/shared/models/task/task";
import { TaskInvoiceResponse } from "src/app/shared/models/task/task-response";
import { ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";
import { TaskInvoiceRequest } from "src/app/shared/models/task/task-request";

@Component({
  selector: 'invoices-modal',
  templateUrl: './invoices-modal.component.html',
  styleUrls: ['./invoices-modal.component.scss']
})
export class InvoicesModalComponent {
  tenantId = this._tenantService.getTenantId();
  @Input() invoice?: Invoice;

  listTaskToShow: Task[] = [];
  listTasksLinkedToInvoice: TaskInvoice[] = [];

  typeExtraFields: TypeExtraField[];

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  invoiceForm: FormGroup;
  invoiceRequest: InvoiceRequest = {};

  supplier$: Observable<string[]>;
  selectedSupplier: Supplier;

  tags$: Observable<string[]>;
  selectedTags: Tag[] = [];

  extraFields: TypeExtraField[] = [];
  fields: FormlyFieldConfig[];

  isMobile = false;

  bucketName: string = 'invoice-documents';
  documents: DocumentResponse[] = [];

  fileToUploads: StorageFile[] = [];
  fileToRemove: StorageFile[] = [];

  private _destroy$ = new Subject<void>();

  constructor(
    public bsModalRef: BsModalRef,
    private _tenantService: TenantService,
    private _invoiceService: InvoicesService,
    private _tasksService: TasksService,
    private _suppliersService: SuppliersService,
    private _tagsService: TagsService,
    private _modalUtilsService: ModalUtilsService,
    private _communicationService: CommunicationService,
    private _extraFieldsUtilsService: ExtraFieldsUtilsService,
  ) {
    this.invoiceForm = new FormGroup({
      invoiceNumber: new FormControl(null, Validators.required),
      invoiceDate: new FormControl(null),
      netAmount: new FormControl(null),
      grossAmount: new FormControl(null),
      supplier: new FormControl(null, Validators.required),
      tag: new FormControl(null),
    });
  }

  ngOnInit() {
    this.autoComplete();
    this.getExtraFields();
    if (this.invoice) {
      this.getLinkFilesTask();
      this.getInvoicesLinkedToTask();
      this.getTasksToShow();
    } else {
      this.invoice = {};
    }
  }

  setValueFields() {
    if (this.invoice.id) {
      this.modalStatus.isCreate = false;
      this.modalStatus.isPatch = true;

      this.invoiceForm.patchValue({
        supplier: this.invoice.supplier?.name ?? null,
        invoiceNumber: this.invoice.invoiceNumber ?? null,
        netAmount: this.invoice.netAmount ?? null,
        grossAmount: this.invoice.grossAmount ?? null,
      });

      if (this.invoice.invoiceDate) {
        let date = new Date(this.invoice.invoiceDate);
        this.invoiceForm.patchValue({
          invoiceDate: date,
        });
      }

      if (this.invoice.tags && this.invoice.tags.length > 0) {
        this.selectedTags = this.invoice.tags;
      }
      if (this.invoice.supplier) {
        this.selectedSupplier = JSON.parse(JSON.stringify(this.invoice.supplier));
      }
    }
  }

  autoComplete() {
    this.tags$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.invoiceForm.controls['tag'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._tagsService.listTags$(this.tenantId, { nameContains: token, limit: 5 })),
      map((response) => {
        let tagNames: string[] = [];
        response.items.forEach((tag) => {
          tagNames.push(tag.name);
        });
        return tagNames;
      }),
    );

    this.supplier$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.invoiceForm.controls['supplier'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._suppliersService.listSuppliers$(this.tenantId, { nameContains: token, limit: 5 })),
      map((response) => {
        let supplierNames: string[] = [];
        response.items.forEach((supplier) => {
          supplierNames.push(supplier.name);
        });
        return supplierNames;
      }),
    );
  }

  onTagSelected(match: TypeaheadMatch) {
    const tagName = match.value;

    let tagExists = false;
    if (this.selectedTags && this.selectedTags.length > 0) {
      tagExists = this.selectedTags.some(tag => tag.name === tagName);
    }

    if (!tagExists) {
      this._tagsService.listTags$(this.tenantId, { name: tagName }).subscribe({
        next: (tags) => {
          if (tags.items.length > 0) {
            const tag = tags.items[0];
            this.selectedTags.push(tag);
          }
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
    }
  }

  onSupplierSelected(match: TypeaheadMatch) {
    const supplierName = match.value;
    this._suppliersService.listSuppliers$(this.tenantId, { name: supplierName, limit: 1 }).subscribe({
      next: (suppliers) => {
        if (suppliers.items.length > 0) {
          this.selectedSupplier = suppliers.items[0];
        }
      }
    });
  }

  private getTasksToShow() {
    let tasksParams: TasksFilter = {
      includeTaskType: true,
      includeTaskCost: true,
    };
    this._tasksService.listTasks$(this.tenantId, tasksParams).subscribe({
      next: (tasks) => {
        this.listTaskToShow = tasks.items;
      }
    });
  }

  private getInvoicesLinkedToTask() {
    let taskInvoicesParams: TaskInvoicesFilter = {
      invoiceId: this.invoice.id,
      includeTask: true,
      includeTaskType: true,
      includeTaskCost: true,
      includeVehicle: true,
      includeUser: true,
      includeAsset: true,
    };
    this._tasksService.listTaskInvoices$(this.tenantId, taskInvoicesParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (taskInvoices: TaskInvoiceResponse) => {
          this.listTasksLinkedToInvoice = taskInvoices.items;
        }
      });
  }

  addTaskToInvoice($event: Task) {
    let taskInvoice: TaskInvoiceRequest = {
      invoiceId: this.invoice.id,
      taskId: $event.id,
    }
    this._tasksService.createTaskInvoice$(this.tenantId, taskInvoice)
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.getInvoicesLinkedToTask();
      });
  }

  onTaskInvoiceLinkDelete(id: string) {
    this._tasksService.deleteTaskInvoice$(this.tenantId, id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.getInvoicesLinkedToTask();
      });
  }

  removeTag(tagId: string) {
    this.selectedTags = this.selectedTags.filter(tag => tag.id !== tagId);
  }

  removeSupplier() {
    this.selectedSupplier = null;
  }

  getExtraFields() {
    this._suppliersService.listSupplierFields$(this.tenantId)
      .subscribe({
        next: (response) => {
          this.setValueFields();
          this.typeExtraFields = response.items.map(x => x as TypeExtraField);
          this.invoice = this.invoice ? this.invoice : {};
          this.invoice.extraFields = this.invoice.extraFields ? this.invoice.extraFields : {};
          this.invoice.extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, this.invoice.extraFields, false);
          this.fields = this.typeExtraFields.map(field =>
            this._extraFieldsUtilsService.createFieldConfig(field, this.invoice.extraFields));
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
  }

  setInCacheFilesToUpload($event: StorageFile) {
    let file = JSON.parse(JSON.stringify($event));
    file.isNew = true;
    this.fileToUploads.push(file);
    this.documents.push(file);
  }

  setInCacheFileToRemove($event: StorageFile) {
    let file = JSON.parse(JSON.stringify($event));
    this.documents = this.documents.filter(f => f.id !== file.id);
    if (file.isNew) {
      this.fileToUploads = this.fileToUploads.filter(f => f.id !== file.id);
    }
    else {
      this.fileToRemove.push(file);
    }
  }

  linkFileToInvoice(file: StorageFile) {

    let request: InvoiceFileRequest = {
      fileId: file.id,
      name: file.name,
    };

    this._invoiceService.createInvoiceFile$(this.tenantId, this.invoice.id, request).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.getLinkFilesTask();
          this.bsModalRef.hide();
        }
      }
    });
  }

  downloadFile($event: StorageFile) {
    let file = $event;
    this._invoiceService.downloadInvoiceFile$(this.tenantId, this.invoice.id, file.id).subscribe({
      next: (response) => {
        const url = window.URL.createObjectURL(response.body);

        const a = document.createElement('a');
        a.href = url;
        a.download = file.name;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    });

  }

  removeFile($event: StorageFile) {
    let file = $event;
    this._invoiceService.deleteInvoiceFile$(this.tenantId, this.invoice.id, file.id).subscribe({
      next: (response) => {
        if (response.status === 204 || response.status === 200) {
          this.getLinkFilesTask();
          this.bsModalRef.hide();
        }
      }
    });
  }

  getLinkFilesTask() {
    this._invoiceService.listInvoiceFiles$(this.tenantId, this.invoice.id)
      .subscribe({
        next: (response) => {
          this.documents = response.items;
        }
      });
  }

  cleanExtraFields() {
    Object.keys(this.invoiceForm.controls).forEach(key => {
      if (this.fields && this.fields.length > 0) {
        this.fields.forEach(field => {
          if (field.key == key) {
            this.invoiceForm.removeControl(key);
          }
        });
      }
    });
  }

  onSubmit() {
    this.invoiceForm.markAllAsTouched();
    this.invoiceForm.updateValueAndValidity();
    if (this.invoiceForm.invalid) {
      return;
    }

    if (this.invoice.id) {
      this.onPatchInvoice();
    } else {
      this.onCreateInvoice();
    }
  }

  onCreateInvoice() {
    this.createRequestFromForm();
    if (this.invoiceForm.invalid) {
      return;
    }

    this._invoiceService.createInvoice$(this.tenantId, this.invoiceRequest).subscribe({
      next: response => {
        if (response) {
          this._communicationService.sendEvent();
          this.manageFiles();
        }
      }
    });
  }

  private manageFiles() {
    if (this.fileToUploads && this.fileToUploads.length > 0) {
      this.fileToUploads.forEach(file => {
        this.linkFileToInvoice(file);
      });
    }

    if (this.fileToRemove && this.fileToRemove.length > 0) {
      this.fileToRemove.forEach(file => {
        this.removeFile(file);
      });
    }

    if ((!this.fileToUploads || this.fileToUploads.length === 0) && (!this.fileToRemove || this.fileToRemove.length === 0)) {
      this.bsModalRef.hide();
    }
  }

  onPatchInvoice() {
    this.createRequestFromForm();
    if (this.invoiceForm.invalid) {
      return;
    }

    this._invoiceService.updateInvoice$(this.tenantId, this.invoice.id, this.invoiceRequest).subscribe({
      next: response => {
        this.invoiceRequest = response;
        if (response) {
          this._communicationService.sendEvent();
          this.manageFiles();
        }
      }
    });
  }

  createRequestFromForm() {
    if (this.typeExtraFields?.length > 0) {
      this.invoiceRequest = this._extraFieldsUtilsService.updateRequestFromForm(this.typeExtraFields, this.invoiceForm.value, this.invoiceRequest);
      this.invoiceRequest.extraFields = this._extraFieldsUtilsService.parseBool(this.typeExtraFields, this.invoiceRequest.extraFields);
    }

    if (!this.invoiceRequest?.extraFields) {
      this.invoiceRequest.extraFields = {};
    }

    this.invoiceRequest.invoiceNumber = this.invoiceForm.controls['invoiceNumber'].value ?? null;
    this.invoiceRequest.netAmount = this.invoiceForm.controls['netAmount'].value ?? null;
    this.invoiceRequest.grossAmount = this.invoiceForm.controls['grossAmount'].value ?? null;
    this.invoiceRequest.supplierId = this.selectedSupplier?.id ?? null;

    if (this.invoiceForm.controls['invoiceDate'].value) {
      let inputInvoiceDate = this.invoiceForm.controls['invoiceDate'].value;
      let invoiceDateUtc = new Date(inputInvoiceDate).toISOString();
      this.invoiceRequest.invoiceDate = invoiceDateUtc;
    }

    if (this.selectedTags && this.selectedTags.length > 0) {
      this.invoiceRequest.tagIds = this.selectedTags.map(tag => tag.id);
    }
  }

  openTaskModal(taskId: string) {
    this._modalUtilsService.openEditTaskModal(taskId).subscribe();
  }
}