import { animate, transition, style, state } from '@angular/animations';
import { trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

export interface Step {
  id: number;
  number: number;
  label: string;
  status: 'checked' | 'unchecked' | 'warning';
  show: boolean;
}

@Component({
  selector: 'stepper',
  standalone: true,
  imports: [
    TranslateModule
  ],
  template: `
    <div class="d-flex align-items-center">
      @for (step of steps; track step) {
          <div class="stepper-container" [@expand]="step.show ? 'visible' : 'hidden'">
            <div class="step-container">
              <div class="line" 
                [class.active]="step.id === currentStep" 
                [class.checked]="step.status === 'checked'"
                [class.warning]="step.status === 'warning'">
              </div>
              <div class="step" 
                [class.active]="step.id === currentStep" 
                [class.checked]="step.status === 'checked'"
                [class.warning]="step.status === 'warning'">
                <div class="circle" 
                  [class.active]="step.id === currentStep" 
                  [class.checked]="step.status === 'checked'"
                  [class.warning]="step.status === 'warning'">
                  @if (step.status !== 'checked') {
                    {{ step.number }}
                  } @else {
                    <i class="bi bi-check2" style="height: 1.25rem;"></i>
                  }
                </div>
              </div>
              <div class="line" 
                [class.active]="step.id === currentStep" 
                [class.checked]="step.status === 'checked'"
                [class.warning]="step.status === 'warning'">
              </div>
            </div>

            <div class="step-container">
              <div class="space"></div>
              <div class="label">
                {{ step.label | translate }}
              </div>
              <div class="space"></div>
            </div>
          </div>
        
      }
    </div>
  `,
  styleUrl: './stepper.component.scss',
  animations: [
    trigger('expand', [
      state('hidden', style({
        width: '0%',
        opacity: 0,
        display: 'none'
      })),
      state('visible', style({
        width: '*',
        opacity: 1,
        display: 'block'
      })),
      transition('hidden => visible', [
        style({ display: 'block' }),
        animate('100ms ease-out')
      ]),
      transition('visible => hidden', [
        animate('100ms ease-in', style({ width: '0%', opacity: 0 }))
      ])
    ])
  ]
})
export class StepperComponent {

  @Input() steps: Step[] = [];
  @Input() currentStep: number = 0;
}
